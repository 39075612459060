/**
 * Component that combines the Network and Device Info pages into one DataGrid.
 */
import React, { useEffect } from 'react';
import { DeviceList, IDevice, setDeviceNetworkSettings } from 'store/slices/devicesSlice';
import { isValidIPV4Address } from 'features/RemoteManagement/SiteDashboard/utils';

import {
  DataGrid,
  GridCellModes,
  GridCellModesModel,
  GridCellParams,
  GridColDef,
  GridInitialState,
  GridPreProcessEditCellProps,
  GridRenderEditCellParams,
  GridRowsProp,
  GridSingleSelectColDef,
  GridValidRowModel,
  GridValueFormatterParams,
  GridValueOptionsParams,
  MuiEvent,
  useGridApiRef
} from '@mui/x-data-grid';
import { formatAsIPAddress, getDeviceModelNumberFromModelType } from 'shared/utils/helperFunctions';
import { useTranslation } from 'react-i18next';
import { Box, Button, Container } from '@mui/material';
import Spinner from 'features/SimBilling/Components/UiParts/Spinner';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import TextInputDataGridCell from 'shared/components/TextInputDataGridCell';
import { fetchGatewayCommand, IDeviceInfoPayload, IGatewayInfoPayload } from 'shared/rmGateway/gwCommandProcessor';
import { gwCommand } from 'shared/rmGateway/gwCommand';
import {
  useBatchUpdateDevicesMutation,
  useGetDeviceListWithSitePublicIdQuery,
  useHandleGatewayCommandMutation,
  useUpdateDeviceMutation
} from 'services/aiphoneCloud';
import { Netmask } from 'netmask';
import { AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT } from 'shared/constantAwsApi';
import { regexNetworkSettingsIpAddress, regexNetworkSettingsSubnetMask } from 'features/RemoteManagement/Types';
import { LoadingProgressStepCounter } from 'features/RemoteManagement/SiteDashboard/Devices/components/LoadingProgressStepCounter';
import { SAVE_DATA_GRID_STATE_TO_LOCAL_STORAGE } from 'shared/constants/config';
import { VALID_STATION_NAME_REGEX } from 'shared/constants/regex';
import { DEFAULT_IP_ADDRESS } from 'shared/constants/ipaddresses';

const OVERRIDE_NETMASK_CHECK = true;
const MAX_LENGTH = 24;
const TGW_DEVICE_TYPE = 18;
const FORCE_EMPTY_DEFAULT_GATEWAY = false;
const VALIDATE_NETMASK_ON_INITIAL_LOAD = false;

// Need to make a validation function for the rows
// This will use the Netmask package to parse the row and get a pattern item for it

/**
 * Validates the given network configuration consisting of an IP address, default gateway, and subnet mask.
 *
 * This function checks if the default gateway is valid within the range specified by the given IP address
 * and subnet mask. It constructs a network address using the IP address and subnet mask, then verifies
 * whether the provided default gateway can be accommodated within this network range. If the default
 * gateway is empty, the function will still succeed, as empty gateways are considered acceptable.
 *
 * @param {string} ipAddress - The IP address to be validated against the subnet mask.
 * @param {string} defaultGateway - The default gateway to be checked for validity within the subnet mask range.
 * @param {string} subnetMask - The subnet mask to be used with the IP address to define a network range.
 * @returns {boolean} Returns true if the default gateway is valid within the network range defined by the IP address and subnet mask, or if the default gateway is empty; otherwise, returns false.
 */
const validateNetmask = (ipAddress: string, defaultGateway: string, subnetMask: string): boolean => {
  // Since the rows require the "Actions" column to update the value, it is acceptable for use to call this on the row update, and we don't need it on mount.
  if (OVERRIDE_NETMASK_CHECK) {
    return true;
  }
  try {
    // Immediately fail and do not construct a new Netmask object.
    if (!testIfValidRegexSubnetMask(subnetMask)) {
      return false;
    }
    const address = `${ipAddress}/${subnetMask}`;
    const newNetmask = new Netmask(address);

    // We can have empty values for the default gateway, which is default, so we must account for that.
    return defaultGateway.length === 0 || newNetmask.contains(defaultGateway);
  } catch (_) {
    // This means there is probably something wrong with crating the Netmask.
    return false;
  }
};

/**
 * Tests if a given station name is valid based on a predefined regex pattern.
 *
 * The regex pattern ensures that:
 * - The string begins and ends without extra characters.
 * - Only the following characters are allowed:
 *   Alphanumeric (A-Za-z0-9), the special characters (+ - _ ? . / ( ) % $ @ !),
 *   and spaces.
 * - The length of the string is between 1 and 24 characters.
 *
 * @param {string} stationName - The station name to be validated.
 * @returns {boolean} - Returns true if the station name is valid, otherwise false.
 */
const testIfValidStationName = (stationName: string): boolean => {
  // /^[A-Za-z0-9+\-_?./()%$@! ]{1,24}$/: This regex ensures that:
  // ^ and $: The string starts and ends respectively.
  // [A-Za-z0-9+\-_?./()%$@! ]: These are the valid characters. This includes:
  // Alphanumeric characters: A-Za-z0-9
  // Special characters listed: + - _ ? . / ( ) % $ @ !
  // Space:
  // {1,24}: The length of the string should be between 1 and 24 characters, inclusive.
  //
  // See: https://github.com/AiphoneCorporation/aiphone-cloud-app/pull/1205?notification_referrer_id=NT_kwDOCp9uK7UxMjY3MDU5MzkyMzoxNzgyMjA1ODc&notifications_query=is%3Aunread#issuecomment-2387253787
  return VALID_STATION_NAME_REGEX.test(stationName);
};

/**
 * Validates if the given IP address is a valid IPv4 address.
 * This function checks the following criteria:
 * 1. The IP address does not start or end with zero.
 * 2. The IP address is not equal to a predefined default IP address.
 * 3. The IP address must match a provided regex pattern for IPv4 addresses.
 *
 * @param {string} ipAddress - The IP address to be validated.
 * @returns {boolean} - True if the IP address is valid, otherwise false.
 */
const testIfValidRegexIPV4 = (ipAddress: string): boolean => {
  if (ipAddress === DEFAULT_IP_ADDRESS) {
    return false;
  }

  // The Regex is valid, however there is an error with how the browser parses it, so I manually have to check if the first and last octets are 0.
  const octets = ipAddress.split('.');
  if (octets[0] === '0' || octets[3] === '0') {
    return false;
  }
  const RegexTester = new RegExp(regexNetworkSettingsIpAddress);
  return RegexTester.test(ipAddress);
};

/**
 * Function to test if a given subnet mask is valid based on a regular expression.
 *
 * @param {string} subnetMask - The subnet mask to be validated.
 * @returns {boolean} - Returns true if the subnet mask is valid, otherwise false.
 */
const testIfValidRegexSubnetMask = (subnetMask: string) => {
  if (subnetMask === DEFAULT_IP_ADDRESS) {
    return false;
  }
  // There is an error with how the browser parses the string, so I manually have to check to see if 127 is in the first octet
  const octets = subnetMask.split('.');
  if (octets[0] === '127') {
    return false;
  }
  const RegexTester = new RegExp(regexNetworkSettingsSubnetMask);
  return RegexTester.test(subnetMask);
};

/**
 * Validates if a given gateway address is either an IPv4 address or a DNS gateway.
 *
 * @param {string} gateway - The gateway address to be tested.
 * @return {boolean} Returns `false` if the gateway is the default IP address. Otherwise, returns `true` if the gateway matches the regular expression for network settings IP address, and `false` otherwise.
 */
const testIfValidRegexIPV4OrDNSGateway = (gateway: string) => {
  if (gateway === DEFAULT_IP_ADDRESS) {
    return false;
  }
  const RegexTester = new RegExp(regexNetworkSettingsIpAddress);
  return RegexTester.test(gateway);
};

// Track the state of the button for the Association
type DeviceAssociationStatus = 'SKIP' | 'ASSOCIATE' | 'TBD' | 'SAVE';

// Added this for more consistency in the code
enum DeviceAssociationStatusEnum {
  SKIP = 'SKIP',
  ASSOCIATE = 'ASSOCIATE',
  TBD = 'TBD',
  SAVE = 'SAVE'
}

// Validate the initial state of the device
// We do not need to check for duplicate station numbers because this is the initial state, so there shall be no
// conflicts

export interface DeviceConfigurationGridProps {
  handleNextStep: () => void;
  handlePreviousStep: () => void;
}

const DeviceConfigurationGrid = (props: DeviceConfigurationGridProps) => {
  const site = useSelector((state: RootState) => state.site);
  const awsPropertyId = site?.siteInfo?.awsPropertyId;
  const gateway = useSelector((state: RootState) => state.devices.DeviceList[site.siteInfo.registeredGatewayPublicId]);
  const gatewayLoading = useSelector((state: RootState) => state.gateway.loading);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const devices = useSelector((state: RootState) => state.devices).DeviceList;
  const [rows, setRows] = React.useState<GridRowsProp>([]);
  const [handleGatewayCommand, { isError: gwCommandError, isLoading: gwLoading }] = useHandleGatewayCommandMutation();
  const dataGridRef = React.useRef<HTMLDivElement>(null);
  const [cellModesModel, setCellModesModel] = React.useState<GridCellModesModel>({});
  const apiRef = useGridApiRef();
  const dispatch = useDispatch();
  const [batchUpdateDevices] = useBatchUpdateDevicesMutation();
  const [error, setError] = React.useState<string | null>(null);
  const [updateDevice] = useUpdateDeviceMutation();
  const [canSubmit, setCanSubmit] = React.useState<boolean>(false);
  const [registrationStatusMessage, setRegistrationStatusMessage] = React.useState<string | undefined>(undefined);
  const [countTotalSteps, setCountTotalSteps] = React.useState<number>(0);
  const [currentStep, setCurrentStep] = React.useState<number>(0);
  const [isSaving, setIsSaving] = React.useState<boolean>(false);
  const [initialState, setInitialState] = React.useState<GridInitialState>({});
  const { refetch } = useGetDeviceListWithSitePublicIdQuery({
    sitePublicId: site.siteInfo.publicId,
    qty: -1,
    page: 0
  });

  const { t } = useTranslation();

  // Fetch devices after they have been added to the unit
  // Logic updated to handle multi-tenant selection in the wizard
  useEffect(() => {
    refetch();
  }, [refetch]);

  /**
   * Use this to iterate the devices and set the initial state of the grid
   * @param devices
   */
  const formatDevices = (devices: DeviceList) => {
    const gateway = site.siteInfo.registeredGatewayPublicId;
    const gatewayDevice = devices[gateway];

    const getStartingIPAddress = (device: IDevice | undefined): number => {
      if (device?.networkSettings?.ipV4Address) {
        const lastOctet = device.networkSettings.ipV4Address.split('.').slice(-1)[0];
        return parseInt(lastOctet, 10);
      }
      return 10; // Default to 10 if the IP address is not available
    };

    const getFirstThreeOctets = (device: IDevice | undefined): string => {
      if (device?.networkSettings?.ipV4Address) {
        return device.networkSettings.ipV4Address.split('.').slice(0, 3).join('.');
      }
      return '192.168.1'; // Default to '192.168.1' if the IP address is not available
    };

    const startingIPAddress = getStartingIPAddress(gatewayDevice);
    const firstThree = getFirstThreeOctets(gatewayDevice);

    if (Object.keys(devices).length === 0) {
      return [] as GridRowsProp;
    }

    let tgwDefaultGateway = '';

    let tgwSubnetMask = '';

    // Use this to get and set the default values for the TGW's Subnet Mask and Default Gateway
    Object.keys(devices).forEach((key: string) => {
      const device: IDevice = devices[key];
      if (device?.basicInfo?.deviceType === TGW_DEVICE_TYPE) {
        tgwDefaultGateway = device?.networkSettings?.ipV4DefaultGateway ?? '';
        tgwSubnetMask = device?.networkSettings?.subnetMask ?? '';
      }
    });

    return Object.keys(devices).map((deviceId, index) => {
      const device = devices[deviceId];
      const ipAddress = device?.networkSettings?.ipV4Address;
      const defaultGateway = device?.networkSettings?.ipV4DefaultGateway;

      return {
        mac_addr: device?.basicInfo?.macAddress ?? '',
        model_number:
          getDeviceModelNumberFromModelType(device?.basicInfo?.deviceModel, device?.basicInfo?.deviceType) ?? '',
        // Adding the device image based on the device model
        device_image: `${AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT}/icons/${getDeviceModelNumberFromModelType(
          device?.basicInfo?.deviceModel,
          device?.basicInfo?.deviceType
        )}.png`,
        station_name: device?.basicInfo?.stationName ?? '',
        station_number: device?.basicInfo?.stationNumber ?? '',
        ip_address:
          ipAddress && ipAddress !== DEFAULT_IP_ADDRESS && ipAddress !== '192.168.1.160'
            ? ipAddress
            : `${firstThree}.${startingIPAddress + index + 1}`,
        subnet_mask: tgwSubnetMask,
        // This should be empty for all devices except for IXGW-TGW devices
        default_gateway:
          defaultGateway && defaultGateway !== DEFAULT_IP_ADDRESS && device?.basicInfo?.deviceType === TGW_DEVICE_TYPE
            ? defaultGateway
            : FORCE_EMPTY_DEFAULT_GATEWAY
            ? ''
            : tgwDefaultGateway, // Empty
        dns: gatewayDevice?.networkSettings?.ipV4PrimaryDns ?? '8.8.8.8',
        // IF the device_type is 18, IXGW-(T)GW then we will "auto-skip" the association
        association_status:
          device.basicInfo.deviceType === TGW_DEVICE_TYPE
            ? DeviceAssociationStatusEnum.SAVE
            : (DeviceAssociationStatusEnum.TBD as DeviceAssociationStatus),
        // These are hidden columns that will not be rendered for the user. They are needed internally, though.
        id: device.publicId,
        // Automatically mark as valid if the device type is 18
        is_valid: device?.basicInfo?.deviceType === TGW_DEVICE_TYPE,
        firmware_version: device?.basicInfo?.firmwareVersion ?? '',
        device_type: device?.basicInfo?.deviceType ?? 0,
        row_is_editable: device?.basicInfo?.deviceType !== TGW_DEVICE_TYPE
      };
    }) as GridRowsProp;
  };

  /**
   * Checks if the row should be skipped based on its association status.
   *
   * @param {GridPreProcessEditCellProps} params - The parameters for the cell being processed.
   * @param {any} apiRef - The reference to the DataGrid API.
   * @returns {boolean} - Returns true if the row's association status is 'SKIP', otherwise false.
   */
  const checkIfShouldSkipRow = (params: GridPreProcessEditCellProps, apiRef: any): boolean => {
    const row = apiRef.current?.getRow(params.id);
    const associationStatus = row?.association_status;
    return associationStatus === DeviceAssociationStatusEnum.SKIP;
  };

  const handleCellUpdate = React.useCallback(() => {
    const rows = apiRef.current?.getRowModels();

    if (rows) {
      for (const row of rows.values()) {
        if (isValidDevice(row)) {
          setCanSubmit(false);
          return; // Exit the function if an invalid row is found
        }
      }

      setCanSubmit(true); // All rows are valid
    }
  }, [setCanSubmit, apiRef]);

  /**
   * Custom function to preprocess the default gateway values for a grid cell.
   *
   * This function checks the value of the default gateway in the cell and validates it.
   * If the row should be skipped based on provided parameters, it clears any existing error.
   * If the value does not match a valid IPv4 or DNS format, it sets an error message.
   *
   * @function
   * @name preProcessDefaultGateway
   * @param {GridPreProcessEditCellProps} params - The properties of the cell being edited.
   * @returns {GridPreProcessEditCellProps} - The updated properties with potential error status.
   */
  const preProcessDefaultGateway = React.useCallback(
    (params: GridPreProcessEditCellProps) => {
      handleCellUpdate();
      // No error if empty string
      if (params.props.value === '') {
        return { ...params.props, error: undefined };
      }
      const validatedStatus = checkIfShouldSkipRow(params, apiRef);
      if (validatedStatus) {
        return { ...params.props, error: undefined };
      }

      const newDefaultGateway = params.props.value as string;
      const row = params.row;
      const { ip_address, subnet_mask } = row;

      if (!testIfValidRegexIPV4OrDNSGateway(params.props.value as string)) {
        return { ...params.props, error: t('Invalid_Default_Gateway') };
      } else if (!validateNetmask(ip_address, newDefaultGateway, subnet_mask)) {
        return { ...params.props, error: t('Invalid_Default_Gateway') };
      } else {
        return { ...params.props, error: undefined };
      }
    },
    [apiRef, t, handleCellUpdate]
  );

  /**
   * Callback function to pre-process and validate the IP address input in a grid cell.
   *
   * This function validates whether the entered IP address is both valid and unique.
   * It first checks if the row should be skipped for validation using `checkIfShouldSkipRow`.
   * If not skipped, it validates the IP address using a regex pattern and checks
   * against existing IP addresses to ensure uniqueness.
   *
   * @param {GridPreProcessEditCellProps} params - The parameters passed to the function which
   *        include props and the id of the cell being edited.
   * @returns {object} An object containing the processed properties, which include either
   *          a validated value or an error message if validation fails.
   */
  const preProcessIPAddress = React.useCallback(
    (params: GridPreProcessEditCellProps) => {
      handleCellUpdate();
      let error = undefined;

      const validatedStatus = checkIfShouldSkipRow(params, apiRef);

      if (validatedStatus) {
        return { ...params.props, error: undefined };
      }

      const { props, id } = params;
      const value = props.value as string;

      const { subnet_mask, default_gateway } = params.row;

      // There cannot be duplicate IP addresses and these must be valid according to regex
      if (!testIfValidRegexIPV4(params.props.value as string)) {
        // Replacing with the exact error message as the DeviceNetworkInfoTabContent
        error = t('IpV4Address_Error_Invalid');
      } else if (!validateNetmask(value, default_gateway, subnet_mask)) {
        error = t('IpV4Address_Error_Invalid');
      } else {
        // Check if the IP address is unique
        const rows = apiRef.current?.getRowModels();
        let isUnique = true;

        for (const value of rows.values()) {
          if (value.ip_address === props.value && value.id !== id) {
            isUnique = false;
            break;
          }
        }

        if (!isUnique) {
          error = t('IP_Address_Must_be_Unique');
        }
      }
      return { ...props, value, error };
    },
    [t, apiRef, handleCellUpdate]
  );

  /**
   * Preprocesses the subnet mask for a given grid cell, validating and setting an error message if necessary.
   * This function uses a callback to process the cell properties, checking if the row should be skipped
   * and validating the subnet mask format.
   *
   * @param {GridPreProcessEditCellProps} params - The properties of the grid cell to be preprocessed.
   * @returns {Object} The updated cell properties, including an error message if the subnet mask is invalid.
   */
  const preProcessSubnetMask = React.useCallback(
    (params: GridPreProcessEditCellProps) => {
      handleCellUpdate();
      const validatedStatus = checkIfShouldSkipRow(params, apiRef);
      if (validatedStatus) {
        return { ...params.props, error: undefined };
      }

      const value = params.props.value as string;

      const { default_gateway, ip_address } = params.row;

      if (!testIfValidRegexSubnetMask(params.props.value as string)) {
        // Replacing with the exact error message as the DeviceNetworkInfoTabContent
        return { ...params.props, error: t('SubnetMask_Error_Invalid') };
      } else if (!validateNetmask(ip_address, default_gateway, value)) {
        // Keep the message below for now as it should be customized later.
        return { ...params.props, error: t('SubnetMask_Error_Invalid') };
      } else {
        return { ...params.props, error: undefined };
      }
    },
    [t, apiRef, handleCellUpdate]
  );

  /**
   * Callback to preprocess DNS values in a data grid cell before final validation.
   *
   * The function first checks if the row should be skipped using `checkIfShouldSkipRow`.
   * If the row should be skipped, the function returns the original properties without any error.
   *
   * If the row should not be skipped, the function then validates if the value is a valid IPv4
   * or DNS gateway using `testIfValidRegexIPV4OrDNSGateway`. If the value is not valid,
   * an 'Invalid_DNS' error message is added to the properties.
   *
   * Dependencies:
   * - `checkIfShouldSkipRow`: Function to determine if a row should be skipped.
   * - `testIfValidRegexIPV4OrDNSGateway`: Function to validate if the DNS value is valid.
   *
   * @param {GridPreProcessEditCellProps} params - The parameters containing the cell properties to be validated.
   * @returns {Object} The updated cell properties with or without error.
   */
  const preProcessDNS = React.useCallback(
    (params: GridPreProcessEditCellProps) => {
      handleCellUpdate();
      const validatedStatus = checkIfShouldSkipRow(params, apiRef);
      if (validatedStatus) {
        return { ...params.props, error: undefined };
      }

      if (!testIfValidRegexIPV4OrDNSGateway(params.props.value as string)) {
        return { ...params.props, error: t('Invalid_DNS') };
      } else {
        return { ...params.props, error: undefined };
      }
    },
    [t, apiRef, handleCellUpdate]
  );

  /**
   * A callback function to preprocess the station number during cell editing and validate the input.
   *
   * This function validates the station number based on several conditions:
   * 1. It checks if the station number should be skipped.
   * 2. Verifies the length of the station number, ensuring it is between 3 and the maximum allowed length.
   * 3. Ensures the station number is a numeric value.
   * 4. Confirms the station number is unique within the row data.
   *
   * @param {GridPreProcessEditCellProps} params - Parameters including the props being edited and their surrounding context.
   * @returns {Object} The updated properties with potential errors based on validation checks.
   */
  const preProcessStationNumber = React.useCallback(
    (params: GridPreProcessEditCellProps) => {
      handleCellUpdate();
      const validatedStatus = checkIfShouldSkipRow(params, apiRef);
      if (validatedStatus) {
        return { ...params.props, error: undefined };
      }

      const { props, id } = params;

      let value = props.value as string;
      let error = undefined;
      if (value.length < 3) {
        error = t('Minimum_3_Digits');
      } else if (value.length > MAX_LENGTH) {
        value = value.substring(0, MAX_LENGTH);
        error = t('Maximum_24_characters');
      }

      // Check if the string can be a number
      if (isNaN(Number(value))) {
        return { ...props, value, error: t('Station_Number_must_be_a_number') };
      }

      const rows = apiRef.current?.getRowModels();

      let isUnique = true;

      for (const value of rows.values()) {
        if (value.station_number === props.value && value.id !== id) {
          isUnique = false;
          break;
        }
      }

      if (!error && !isUnique) {
        error = t('Must_be_unique');
      }

      return { ...props, value, error };
    },
    [t, apiRef, handleCellUpdate]
  );

  /**
   * A function for preprocessing the station name during cell editing in a grid.
   * Utilizes React's useCallback hook to memoize the callback function, optimizing performance.
   *
   * @param {GridPreProcessEditCellProps} params - The parameters for preprocessing the cell.
   * @returns {object} The processed properties, including any validation errors.
   */
  const preProcessStationName = React.useCallback(
    (params: GridPreProcessEditCellProps) => {
      handleCellUpdate();
      const validatedStatus = checkIfShouldSkipRow(params, apiRef);
      if (validatedStatus) {
        return { ...params.props, error: undefined };
      }

      const { props } = params;
      let value = props.value as string;
      let error;
      if (value.length > MAX_LENGTH) {
        value = value.substring(0, MAX_LENGTH);
        error = t('Maximum_24_characters');
      } else if (value.length === 0) {
        error = t('Station_Name_required');
      }

      // Check that the station name is valid
      if (!testIfValidStationName(value)) {
        // Test the regex pattern for valid station name, and set error if found
        error = t('Invalid_Station_Name');
      }

      // Removing station name duplicate check
      return { ...props, value, error };
    },
    [t, apiRef, handleCellUpdate]
  );

  /**
   * Handles click events on grid cells.
   *
   * This callback function is used to manage the click events on cells within a data grid. It is designed to ignore clicks on portal elements and to prevent certain cells (like image cells) from becoming editable. Additionally, it ensures that any cells with errors do not change mode. For other cells, this function sets the clicked cell to edit mode and reverts other cells in the same or different rows back to view mode.
   *
   * @param {GridCellParams} params - The parameters of the clicked cell.
   * @param {MuiEvent<React.MouseEvent>} event - The mouse click event.
   */
  const handleCellClick = React.useCallback((params: GridCellParams, event: MuiEvent<React.MouseEvent>) => {
    // Ignore portal
    if ((event.target as any).nodeType === 1 && !event.currentTarget.contains(event.target as Element)) {
      return;
    }

    // Prevent the image cell from becoming editable
    // Previously this would render text with the image name/url as text input if we clicked on this
    if (params.field === 'device_image') {
      return;
    }

    const row = apiRef.current?.getRow(params.id);
    if (row && row[params.field]?.error) {
      return;
    }

    setCellModesModel((prevModel) => {
      return {
        // Revert the mode of the other cells from other rows
        ...Object.keys(prevModel).reduce(
          (acc, id) => ({
            ...acc,
            [id]: Object.keys(prevModel[id]).reduce(
              (acc2, field) => ({
                ...acc2,
                [field]: { mode: GridCellModes.View }
              }),
              {}
            )
          }),
          {}
        ),
        [params.id]: {
          // Revert the mode of other cells in the same row
          ...Object.keys(prevModel[params.id] || {}).reduce(
            (acc, field) => ({ ...acc, [field]: { mode: GridCellModes.View } }),
            {}
          ),
          [params.field]: { mode: GridCellModes.Edit }
        }
      };
    });
  }, []);

  /**
   * Callback function that preprocesses the selection status for an association.
   *
   * This function checks the value of the provided cell properties. If the value is 'TBD',
   * it returns an object with an error indicating that a valid option must be selected.
   * Otherwise, it returns the properties without any error.
   *
   * @param {GridPreProcessEditCellProps} params - The parameters containing cell properties.
   * @returns {object} The modified properties, including an error if the value is 'TBD'.
   */
  const preProcessSelectAssociationStatus = React.useCallback(
    (params: GridPreProcessEditCellProps) => {
      handleCellUpdate();
      const { props } = params;
      const value = params.props.value as string;
      // Check if the value is not 'TBD'
      if (value === 'TBD') {
        return { ...props, error: t('Must_Select_Option') };
      } else {
        return { ...props, error: undefined };
      }
    },
    [t, handleCellUpdate]
  );

  const handleCellModesModelChange = (newModel: GridCellModesModel) => {
    setCellModesModel(newModel);
  };

  /**
   * @function isCellOpenForRow
   * @description Check if any cell is open for a row
   */
  const isCellOpenForRow = (rowId: string) => {
    const state = apiRef.current.state;
    const row = state.editRows[rowId];
    return row ? Object.values(row).some((cell) => cell.isEditing) : false;
  };

  const isValidDevice = React.useCallback(
    (device: GridValidRowModel): boolean => {
      const rows = apiRef.current?.getRowModels();

      const isUnique = (field: string, value: string) => {
        return !Object.values(rows).some((row) => row[field] === value && row.id !== device.id);
      };

      if (device.association_status === DeviceAssociationStatusEnum.SKIP) {
        return true;
      }

      let defaultGatewayIsValid = true;
      if (device.default_gateway.length > 0) {
        defaultGatewayIsValid = isValidIPV4Address(device.default_gateway);
      }

      return (
        testIfValidRegexIPV4(device.ip_address) &&
        testIfValidRegexSubnetMask(device.subnet_mask) &&
        defaultGatewayIsValid &&
        testIfValidRegexIPV4OrDNSGateway(device.dns) &&
        // Check if the station number is actually a number
        !isNaN(Number(device.station_number)) &&
        // Check if the station number is at least 3 digits and less than 25 unique
        device.station_number.length >= 3 &&
        device.station_number.length <= MAX_LENGTH &&
        isUnique('station_number', device.station_number) &&
        // Check if the station name is between 1 and 24 characters and unique
        device.station_name.length > 0 &&
        device.station_name.length <= MAX_LENGTH &&
        // Check if the IP address is unique
        isUnique('ip_address', device.ip_address) &&
        // Check if the association_status is not 'TBD'
        device.association_status !== DeviceAssociationStatusEnum.TBD &&
        // Verify netmask parsing
        validateNetmask(device.ip_address, device.default_gateway, device.subnet_mask)
      );
    },
    [apiRef]
  );

  // Associate with Static IP configuration - associate one device at a time
  /**
   * A callback function to handle static association of a device with the system.
   *
   * This function performs the necessary operations to associate a device
   * by communicating with the gateway and updating the device's network settings.
   * It sets loading state during the operation, handles success and error states,
   * and updates the relevant device information in the application's state.
   *
   * @param {Object} device - The device to be associated, which should contain the necessary network and identification properties.
   */
  const staticAssociation = React.useCallback(
    async (device: any) => {
      setIsLoading(true);
      // Pass in the device row data
      const deviceMacAddress = device.mac_addr;

      let whichId = site.siteInfo.systemId ? site.siteInfo.systemId : gateway.basicInfo.adminId;
      let whichPassword = site.siteInfo.systemPassword ? site.siteInfo.systemPassword : gateway.basicInfo.adminPass;
      const isGatewayFirstSync = gateway?.lastSyncedOn === null;
      if (isGatewayFirstSync && !site.siteInfo.systemId && !site.siteInfo.systemPassword) {
        whichId = 'admin';
        whichPassword = 'admin';
      }

      // Get the device from the deviceList slice
      const dataDevice = devices[deviceMacAddress];

      const gatewayInfo = {
        awsPropertyId,
        gwMacAddress: gateway?.basicInfo?.macAddress,
        gwId: whichId,
        gwPassword: whichPassword,
        gwIpAddress: gateway?.networkSettings?.ipV4Address
      } as IGatewayInfoPayload;
      const deviceInfo = {
        deviceMacAddress,
        deviceIpV4Address: device.ip_address,
        deviceSubnetMask: device.subnet_mask,
        deviceIpV4DefaultGateway: device.default_gateway,
        deviceName: device.station_name
      } as IDeviceInfoPayload;

      try {
        const ioTPayload = fetchGatewayCommand('sendCommand', gwCommand.ASSOCIATE, gatewayInfo, deviceInfo, 'static');
        if (!ioTPayload) {
          setError(t('Error_associating_device'));
          setIsLoading(false);
        }
        await handleGatewayCommand(ioTPayload);
        const fetchPayload = fetchGatewayCommand('fetchResult', gwCommand.ASSOCIATE, gatewayInfo, deviceInfo, 'static');
        const fetchResponse = await handleGatewayCommand(fetchPayload);
        if (gwCommandError || fetchResponse.error || !fetchResponse.data.statusCode.includes('200')) {
          setError(t('Error_associating_device'));
          setIsLoading(false);
        }
        updateDevice({
          device: {
            publicId: device.id,
            sitePublicId: dataDevice.sitePublicId,
            networkSettings: {
              ...dataDevice.networkSettings
            }
          }
        });

        const updatedDevice = {
          publicId: device.id,
          sitePublicId: dataDevice.sitePublicId,
          networkSettings: {
            ...dataDevice.networkSettings,
            ipV4Address: device.ip_address,
            subnetMask: device.subnet_mask,
            ipV4DefaultGateway: device.default_gateway,
            dns: device.dns
          }
        };

        dispatch(setDeviceNetworkSettings(updatedDevice));
        setIsLoading(false);
      } catch (error) {
        setError(t('Error_associating_device'));
      }
    },
    [t, setError, setIsLoading, updateDevice]
  );

  // Send requests for registration.
  /**
   * Handles the process of saving and continuing to the next step.
   *
   * This function performs several tasks in sequence:
   * 1. Retrieves all rows from the `apiRef` data table.
   * 2. Validates each row using the `validateRow` function.
   * 3. Categorizes rows into three groups: devices to associate, devices to save, and devices to skip.
   * 4. Associates devices in the `devicesToAssociate` array with the cloud using the `staticAssociation` function.
   * 5. Saves information about the devices in the `devicesToSave` array using the `batchUpdateDevices` function.
   * 6. Advances to the next step using the `props.handleNextStep` function.
   *
   * If any row fails validation, an error is thrown and caught in the catch block. The error is then handled
   * and a friendly error message is displayed.
   *
   * The state variables `setIsSaving`, `setCountTotalSteps`, `setCurrentStep`, `setRegistrationStatusMessage`,
   * and `setError` are used to manage the status of the saving process and error handling.
   *
   * The dependencies for this callback function include the state setters `setIsSaving`, `setCountTotalSteps`,
   * `setCurrentStep`, and the localization function `t`.
   */
  const handleSaveAndContinue = React.useCallback(async () => {
    // First, get all rows
    const rows = apiRef.current?.getRowModels();

    // If all rows are valid, we continue on in this function
    // If any row is invalid, we throw an error
    for (const value of rows.values()) {
      const errorMessage = validateRow(value);
      if (errorMessage) {
        throw new Error(errorMessage);
      }
    }

    try {
      setIsSaving(true);

      // We need to make two arrays: one for all the devices to associate, and one for all the devices to save
      const devicesToAssociate = [];
      const devicesToSave = [];
      const devicesToSkip = [];

      // Loop through each row, if the association status is 'ASSOCIATE', add it to the devicesToAssociate array
      // If the association status is 'SAVE', add it to the devicesToSave array. When adding to the devicesToSave array,
      // we need to make sure to create an IDevicesToAdd object, which is a subset of the IDevice object
      for (const value of rows.values()) {
        if (value.association_status === DeviceAssociationStatusEnum.ASSOCIATE) {
          devicesToAssociate.push(value);
          devicesToSave.push({
            publicId: value.id,
            basicInfo: {
              macAddress: value.mac_addr,
              stationNumber: value.station_number,
              stationName: value.station_name,
              firmwareVersion: value.firmware_version
            },
            networkSettings: {
              ipV4Address: value.ip_address,
              subnetMask: value.subnet_mask,
              ipV4DefaultGateway: value.default_gateway
            }
          });
        } else if (value.association_status === DeviceAssociationStatusEnum.SAVE) {
          devicesToSave.push({
            publicId: value.id,
            basicInfo: {
              macAddress: value.mac_addr,
              stationNumber: value.station_number,
              stationName: value.station_name,
              firmwareVersion: value.firmware_version
            },
            networkSettings: {
              ipV4Address: value.ip_address,
              subnetMask: value.subnet_mask,
              ipV4DefaultGateway: value.default_gateway
            }
          });
        } else if (value.association_status === DeviceAssociationStatusEnum.SKIP) {
          devicesToSkip.push(value);
        }
      }

      // If all devices are set to "Skip", skip to the next step
      if (devicesToSkip.length === rows.size) {
        setIsSaving(false);
        props.handleNextStep();
        return;
      }

      // Because the devicesToSave happens in a single batch call, we will count it as one step. This means at most,
      //   we will have devicesToAssociate.length + 1 steps if there are devices to save.
      setCountTotalSteps(devicesToAssociate.length + (devicesToSave.length > 0 ? 1 : 0));

      let localStepCounter = 1;
      // Associate devices
      // Go through each device in the devicesToAssociate array and associate it with the cloud
      // using the async function staticAssociation

      for (const device of devicesToAssociate) {
        setCurrentStep(localStepCounter);
        await staticAssociation(device);
        setRegistrationStatusMessage(
          `${t('Associating_Device')} ${device.station_name} ${device.mac_addr.toString()}...`
        );
        localStepCounter++;
      }

      // Save devices.
      // This is done in one single batch call
      const params = {
        sitePublicId: site.siteInfo.publicId,
        devices: devicesToSave
      };
      setCurrentStep(localStepCounter);
      setRegistrationStatusMessage(t('Now_Saving_Devices'));
      await batchUpdateDevices(params);

      // go to next step
      setCountTotalSteps(0);
      setCurrentStep(0);
      setRegistrationStatusMessage(undefined);
      setIsSaving(false);

      props.handleNextStep();
    } catch (error: any) {
      // Handle the error
      const stringError = error.toString();
      let friendlyErrorMessage = stringError;
      if (stringError.includes('device_basic_info_station_number_unique_per_site')) {
        friendlyErrorMessage = t('Duplicate_Station_Number_Detected');
      } else if (stringError.includes('device_basic_info_station_name_unique')) {
        friendlyErrorMessage = t('Duplicate_Station_Name_Detected');
      }
      setError(friendlyErrorMessage);
      setCountTotalSteps(0);
      setCurrentStep(0);
      setRegistrationStatusMessage(undefined);
      setIsSaving(false);
    }
  }, [setIsSaving, setCountTotalSteps, setCurrentStep, t]);

  /**
   * Validates the given station name based on its length.
   *
   * @param {string} stationName - The name of the station to be validated.
   * @returns {boolean} - Returns true if the station name is valid, otherwise false.
   */
  const validateStationName = (stationName: string): boolean => {
    // If over 24 characters long or empty, this returns false
    return stationName.length > MAX_LENGTH || stationName.length === 0;
  };

  /**
   * Validates a given station number.
   *
   * This function checks if the provided station number is valid based on the following criteria:
   * - The station number must be between 3 and 24 characters in length.
   * - The station number must be a valid integer.
   *
   * @param {string} stationNumber - The station number to validate.
   * @return {boolean} - Returns `true` if the station number is valid, otherwise returns `false`.
   */
  const validateStationNumber = (stationNumber: string): boolean => {
    // If not 3-24 characters, or a number, this returns false
    return stationNumber.length < 3 || stationNumber.length > MAX_LENGTH || !Number.isInteger(parseInt(stationNumber));
  };

  /**
   * Determines if a specified field value in a given row is unique across a collection of rows.
   *
   * @param {Array} rows - The array of rows to check against.
   * @param {Object} row - The row containing the field value to be checked.
   * @param {string} field - The field name whose value is to be checked for uniqueness.
   * @return {boolean} True if the field value is unique within the collection of rows, otherwise false.
   */
  const isUnique = (rows: any, row: any, field: string): boolean => {
    for (const value of rows.values()) {
      if (value[field] === row[field] && value.id !== row.id) {
        return false;
      }
    }
    return true;
  };

  /**
   * @function validateRow
   * @description Returns a string if there is an error, otherwise returns null
   * @param {GridValidRowModel} row The row to validate
   * @return {string | null} Returns a string if there is an error, otherwise returns null
   */
  /**
   * Callback function to validate a row in the grid.
   *
   * This function performs several validation checks on the provided row:
   * - Ensures that no cells within the row are still in edit mode.
   * - Skips validation if the row's association status is 'SKIP'.
   * - Validates the station name to meet specific requirements.
   * - Ensures the station number meets minimum length requirements and is a number.
   * - Checks the uniqueness of the station number, IP address, and station name within the grid.
   * - Validates the format of IP addresses, subnet mask, default gateway, and DNS.
   * - Ensures that an association status of 'TBD' is flagged.
   *
   * @param {GridValidRowModel} row - The row to be validated.
   * @returns {string | null} A string that represents the validation error message, or null if the row passes validation.
   */
  const validateRow = React.useCallback(
    (row: GridValidRowModel): string | null => {
      // Tracking the editing/focus state of the cell
      // We shall not allow the user to save the data if the cell is still in edit mode
      if (isCellOpenForRow(row.id)) {
        return t('There_are_unsaved_changes');
      }

      // If we skip the row, there is no need to validate the other fields in the row
      // NOTE: The above check is necessary because we do not want to allow rows with cells in edit mode to be able
      //  to validate the row.
      if (row.association_status === 'SKIP') {
        return null;
      }

      if (validateStationName(row.station_name)) {
        return t('Station_Name_requirements');
      }

      if (validateStationNumber(row.station_number)) {
        return t('Station_Number_min_length');
      }

      if (isNaN(Number(row.station_number))) {
        return t('Station_Number_must_be_a_number');
      }

      const rows = apiRef.current?.getRowModels();

      if (!isUnique(rows, row, 'station_number')) {
        return t('Station_Number_must_be_unique');
      }

      if (!isUnique(rows, row, 'ip_address')) {
        return t('IP_Address_Must_be_Unique');
      }

      if (!isUnique(rows, row, 'station_name')) {
        return t('Station_Name_must_be_unique');
      }

      if (!isValidIPV4Address(row.ip_address)) {
        return t('Invalid_IP_Address');
      }

      if (!isValidIPV4Address(row.subnet_mask)) {
        return t('Invalid_Subnet_Mask');
      }

      if (row.default_gateway.length > 0) {
        if (!isValidIPV4Address(row.default_gateway)) {
          return t('Invalid_Default_Gateway');
        }
      }

      if (!isValidIPV4Address(row.dns)) {
        return t('Invalid_DNS');
      }

      if (!validateNetmask(row.ip_address, row.default_gateway, row.subnet_mask)) {
        return t('Invalid_Netmask');
      }

      // This indicates that no selection of "Associate", "Skip", or "Save" has been made
      if (row.association_status === 'TBD') {
        return t('Association_TBD');
      }

      // By default, return null if there are no errors
      return null;
    },
    [t]
  );

  // Initial Cell state validators
  /**
   * Validates the station number for initial mount check.
   *
   * This callback function checks the given station number against multiple criteria:
   * - Must be a minimum of 3 digits long.
   * - Must be less than the maximum allowed length.
   * - Must be a numeric value.
   * - Must be unique among all other station numbers.
   *
   * @param {string | undefined} stationNumber The station number to validate.
   * @param {string} id The unique identifier for the current station.
   * @returns {string | null} Returns a translated error message if validation fails, or null if the station number is valid.
   */
  const _initialMountCheckStationNumber = React.useCallback(
    (stationNumber: string | undefined, id: string) => {
      if (!stationNumber) {
        return t('Minimum_3_Digits');
      }
      // Validate the string by checking if it is 3 digits or more,
      // less than 24 character, is a number, and is unique from the other station numbers
      if (stationNumber.length < 3) {
        return t('Minimum_3_Digits');
      } else if (stationNumber.length > MAX_LENGTH) {
        return t('Maximum_24_characters');
      } else if (isNaN(Number(stationNumber))) {
        return t('Station_Number_must_be_a_number');
      }
      const rows = apiRef.current?.getRowModels();
      let isUnique = true;
      for (const value of rows.values()) {
        if (value.station_number === stationNumber && value.id !== id) {
          isUnique = false;
          break;
        }
      }
      if (!isUnique) {
        return t('Must_be_unique');
      } else {
        return null;
      }
    },
    [t, apiRef]
  );

  /**
   * Validates the initial mount check for the station name.
   *
   * This function performs several validation checks on the provided station name:
   * - Ensures the station name is defined, otherwise returns a translated required error message.
   * - Ensures the station name length is less than the maximum allowed length.
   * - Ensures the station name meets specific format requirements defined by `testIfValidStationName`.
   *
   * @param {string | undefined} stationName The station name to validate.
   * @returns {string | undefined} A translated error message if validation fails, otherwise undefined.
   */
  const _initialMountCheckStationName = React.useCallback(
    (stationName: string | undefined) => {
      if (!stationName) {
        return t('Station_Name_required');
      }
      // Check if the station name is unique and if it is less than 24 characters
      if (stationName.length > MAX_LENGTH) {
        return t('Maximum_24_characters');
      } else if (stationName.length === 0) {
        return t('Station_Name_required');
      }

      // Check the regex station name
      if (!testIfValidStationName(stationName)) {
        return t('Invalid_Station_Name');
      }

      // Removing station name duplicate check
    },
    [t, apiRef]
  );

  /**
   * Validates an IP address and ensures its uniqueness within a dataset.
   *
   * Checks if the provided IP address is not empty, follows the IPv4 format,
   * and confirms it's unique within existing dataset rows, excluding the row
   * being edited (identified by its ID).
   *
   * @param {string | undefined} ipAddress - The IP address to validate.
   * @param {string} id - The unique identifier for the current row being edited.
   * @returns {string | null} - A localized error message if the IP address is invalid or not unique, or null if validation passes.
   */
  const _initialMountCheckIPAddress = React.useCallback(
    (ipAddress: string | undefined, id: string) => {
      if (!ipAddress) {
        return t('IP_Address_Required');
      }

      // Check if the IP address is valid and if it is unique
      if (!testIfValidRegexIPV4(ipAddress)) {
        return t('Invalid_IP_Address');
      }

      const rows = apiRef.current?.getRowModels();
      let isUnique = true;
      for (const value of rows.values()) {
        if (value.ip_address === ipAddress && value.id !== id) {
          isUnique = false;
          break;
        }
      }

      if (!isUnique) {
        return t('Must_be_unique');
      } else {
        return null;
      }
    },
    [t, apiRef]
  );

  /**
   * A callback function used to validate a subnet mask.
   *
   * This function checks if the provided subnet mask is defined and valid. If the
   * subnet mask is not defined, it returns a message indicating that the subnet
   * mask is required. If the subnet mask is invalid, it returns an error message
   * indicating that the subnet mask is invalid. If the subnet mask is valid,
   * it returns null.
   *
   * @param {string | undefined} subnetMask - The subnet mask to be validated.
   * @returns {string | null} - Returns a message string indicating the validation
   *    result or null if the subnet mask is valid.
   */
  const _initialMountCheckSubnetMask = React.useCallback(
    (subnetMask: string | undefined, row: any) => {
      if (!subnetMask) {
        return t('Subnet_Mask_Required');
      }

      const ipAddress = row.ip_address;
      const defaultGateway = row.default_gateway;

      // Check if the subnet mask is valid
      if (!testIfValidRegexSubnetMask(subnetMask)) {
        return t('Invalid_Subnet_Mask');
      } else {
        if (VALIDATE_NETMASK_ON_INITIAL_LOAD) {
          if (!validateNetmask(ipAddress, defaultGateway, subnetMask)) {
            return t('Invalid_Subnet_Mask');
          }
        }
        return null;
      }
    },
    [t]
  );

  /**
   * Callback to validate the initial mount check of a default gateway.
   *
   * This function checks if a default gateway is provided and if it is valid based on
   * either an IPV4 or DNS format. It returns a translation key string indicating the
   * validation result.
   *
   * @param {string | undefined} defaultGateway - The default gateway to be validated.
   * @returns {string | null} Translation key string indicating either an error or null if valid.
   */
  const _initialMountCheckDefaultGateway = React.useCallback(
    (defaultGateway: string | undefined, row: any) => {
      // By default, this is empty
      if (!defaultGateway || defaultGateway.length === 0) {
        return null;
      }

      const subnetMask = row.subnet_mask;
      const ipAddress = row.ip_address;

      // Check if the default gateway is valid
      if (!testIfValidRegexIPV4OrDNSGateway(defaultGateway)) {
        return t('Invalid_Default_Gateway');
      } else {
        if (VALIDATE_NETMASK_ON_INITIAL_LOAD) {
          if (!validateNetmask(ipAddress, defaultGateway, subnetMask)) {
            return t('Invalid_Default_Gateway');
          }
        }
        return null;
      }
    },
    [t]
  );

  /**
   * Callback function to validate the DNS input during the initial mount.
   *
   * @param {string | undefined} dns - The DNS string to be validated.
   * @returns {string | null} - Returns a validation message or null if the DNS is valid.
   */
  const _initialMountCheckDNS = React.useCallback(
    (dns: string | undefined) => {
      if (!dns) {
        return t('Required');
      }
      // Check if the DNS is valid
      if (!testIfValidRegexIPV4OrDNSGateway(dns)) {
        return t('Invalid_DNS');
      } else {
        return null;
      }
    },
    [t]
  );

  const columns = React.useMemo(
    () => [
      {
        field: 'mac_addr',
        headerName: t('MAC_Address'),
        width: 160,
        editable: false,
        hideable: false,
        type: 'string',
        cellClassName: 'bold-text'
      } as GridColDef,
      {
        field: 'model_number',
        headerName: t('Model_Number'),
        width: 110,
        editable: false,
        hideable: false,
        type: 'string',
        cellClassName: 'bold-text'
      } as GridColDef,
      {
        field: 'device_image',
        headerName: t('Device'),
        align: 'center',
        hideable: true,
        headerAlign: 'center', // To match the centering of the image
        editable: false,
        display: 'flex',
        width: 100,
        cellClassName: 'flex justify-center items-center',
        renderCell: (params) => <img src={params.value} alt={params.value} style={{ maxWidth: 60, maxHeight: 40 }} />
      } as GridColDef,
      {
        field: 'station_name',
        headerName: t('Station_Name'),
        headerAlign: 'center',
        width: 150,
        editable: true,
        display: 'flex',
        align: 'center',
        hideable: false,
        type: 'string',
        cellClassName: 'cursor-pointer text-center station-name',
        renderEditCell: (props: GridRenderEditCellParams) => (
          <TextInputDataGridCell
            error={_initialMountCheckStationName(props.value)}
            {...props}
            checkForEmpty={true}
            readOnly={!props.row.row_is_editable}
          />
        ),
        renderCell: (props: GridRenderEditCellParams) => (
          <TextInputDataGridCell
            error={_initialMountCheckStationName(props.value)}
            {...props}
            checkForEmpty={true}
            readOnly={!props.row.row_is_editable}
          />
        ),
        preProcessEditCellProps: preProcessStationName,
        // Need to limit the length of the station name to 24 characters with maxLength
        maxLength: MAX_LENGTH,
        minLength: 1,
        valueFormatter: (params: GridValueFormatterParams) => {
          // Only allow VALID_STATION_NAME regex pattern
          return params.value.replace(VALID_STATION_NAME_REGEX, '');
        }
      } as GridColDef,
      {
        field: 'station_number',
        headerName: t('Station_Number'),
        headerAlign: 'center',
        width: 120,
        display: 'flex',
        align: 'center',
        editable: true,
        hideable: false,
        preProcessEditCellProps: preProcessStationNumber,
        cellClassName: 'cursor-pointer text-center',
        renderEditCell: (props: GridRenderEditCellParams) => (
          <TextInputDataGridCell
            error={_initialMountCheckStationNumber(props.value, props.row.id)}
            {...props}
            checkForEmpty={true}
            readonly={!props.row.row_is_editable}
          />
        ),
        renderCell: (props: GridRenderEditCellParams) => (
          <TextInputDataGridCell
            error={_initialMountCheckStationNumber(props.value, props.row.id)}
            {...props}
            checkForEmpty={true}
            readOnly={!props.row.row_is_editable}
          />
        ),
        maxLength: MAX_LENGTH,
        minLength: 3,
        valueFormatter: (params: GridValueFormatterParams) => {
          // Only allow digits
          return params.value.replace(/\D/g, '');
        }
      } as GridColDef,
      {
        field: 'ip_address',
        headerName: t('IP_Address'),
        headerAlign: 'center',
        type: 'string',
        minWidth: 180,
        display: 'flex',
        editable: true,
        valueParser: (value: string) => {
          // Remove all non-numeric and non-dot characters
          return value.replace(/[^0-9.]/g, '');
        },
        valueFormatter: (params: GridValueFormatterParams) => {
          return formatAsIPAddress(params.value);
        },
        preProcessEditCellProps: preProcessIPAddress,
        align: 'center',
        renderEditCell: (props: GridRenderEditCellParams) => (
          <TextInputDataGridCell
            error={_initialMountCheckIPAddress(props.value, props.row.id)}
            {...props}
            checkForEmpty={true}
            readOnly={!props.row.row_is_editable}
          />
        ),
        renderCell: (props: GridRenderEditCellParams) => (
          <TextInputDataGridCell
            error={_initialMountCheckIPAddress(props.value, props.row.id)}
            {...props}
            checkForEmpty={true}
            readOnly={!props.row.row_is_editable}
          />
        ),
        cellClassName: 'text-center'
      } as unknown as GridColDef,
      {
        field: 'subnet_mask',
        type: 'string',
        headerName: t('Subnet_Mask'),
        headerAlign: 'center',
        editable: true,
        width: 150,
        valueParser: (value: string) => {
          // Remove all non-numeric and non-dot characters
          return value.replace(/[^0-9.]/g, '');
        },
        valueFormatter: (params: GridValueFormatterParams) => {
          return formatAsIPAddress(params.value);
        },
        preProcessEditCellProps: preProcessSubnetMask,
        display: 'flex',
        align: 'center',
        renderEditCell: (props: GridRenderEditCellParams) => (
          <TextInputDataGridCell
            error={_initialMountCheckSubnetMask(props.value, props.row)}
            {...props}
            readOnly={!props.row.row_is_editable}
            checkForEmpty={true}
          />
        ),
        renderCell: (props: GridRenderEditCellParams) => (
          <TextInputDataGridCell
            error={_initialMountCheckSubnetMask(props.value, props.row)}
            {...props}
            checkForEmpty={true}
            readOnly={!props.row.row_is_editable}
          />
        )
      } as unknown as GridColDef,
      {
        width: 150,
        field: 'default_gateway',
        type: 'string',
        headerName: t('Default_Gateway'),
        headerAlign: 'center',
        editable: true,
        display: 'flex',
        preProcessEditCellProps: preProcessDefaultGateway,
        valueParser: (value: string) => {
          // Remove all non-numeric and non-dot characters
          return value.replace(/[^0-9.]/g, '');
        },
        valueFormatter: (params: GridValueFormatterParams) => {
          return formatAsIPAddress(params.value);
        },
        align: 'center',
        renderEditCell: (props: GridRenderEditCellParams) => (
          <TextInputDataGridCell
            error={_initialMountCheckDefaultGateway(props.value, props.row)}
            {...props}
            checkForEmpty={false}
            readOnly={!props.row.row_is_editable}
          />
        ),
        renderCell: (props: GridRenderEditCellParams) => (
          <TextInputDataGridCell
            error={_initialMountCheckDefaultGateway(props.value, props.row)}
            {...props}
            checkForEmpty={false}
            readOnly={!props.row.row_is_editable}
          />
        )
      } as unknown as GridColDef,
      {
        field: 'dns',
        type: 'string',
        headerName: t('DNS'),
        headerAlign: 'center',
        editable: true,
        display: 'flex',
        width: 150,
        preProcessEditCellProps: preProcessDNS,
        valueParser: (value: string) => {
          // Remove all non-numeric and non-dot characters
          return value.replace(/[^0-9.]/g, '');
        },
        valueFormatter: (params: GridValueFormatterParams) => {
          return formatAsIPAddress(params.value);
        },
        align: 'center',
        renderEditCell: (props: GridRenderEditCellParams) => (
          <TextInputDataGridCell
            error={_initialMountCheckDNS(props.value)}
            {...props}
            checkForEmpty={true}
            readOnly={!props.row.row_is_editable}
          />
        ),
        renderCell: (props: GridRenderEditCellParams) => (
          <TextInputDataGridCell
            error={_initialMountCheckDNS(props.value)}
            {...props}
            checkForEmpty={true}
            readOnly={!props.row.row_is_editable}
          />
        )
      } as unknown as GridColDef,
      {
        // This is association/disassociation column
        field: 'association_status', // Should be set_device_id, but using this as a unique identifier
        headerName: t('Actions'),
        width: 150,
        editable: true,
        align: 'left',
        display: 'flex',
        hideable: false,
        type: 'singleSelect',
        preProcessEditCellProps: preProcessSelectAssociationStatus,
        // If the gateway is registered, we can set the device IP address
        valueOptions: (params: GridValueOptionsParams) => {
          return gateway
            ? params.row?.device_type === 18
              ? [
                  { value: 'TBD', label: t('Select_an_Option') },
                  { value: 'SAVE', label: t('Save_for_Later') }
                ]
              : [
                  { value: 'TBD', label: t('Select_an_Option') },
                  { value: 'ASSOCIATE', label: t('Set_Device_IP') },
                  { value: 'SAVE', label: t('Save_for_Later') }
                ]
            : // Otherwise we can only save for later
              [
                { value: 'TBD', label: t('Select_an_Option') },
                { value: 'SAVE', label: t('Save_for_Later') }
              ];
        }
      } as GridSingleSelectColDef
    ],
    [rows, apiRef, gateway, t]
  );

  /**
   * Callback function to process the update of a row in a data grid.
   *
   * This function is used to validate the new row data against certain rules,
   * check the validity of the device, and update the 'canSubmitDevices' state accordingly.
   * If the new row passes all validations, it merges the old row data with the new row data
   * and sets the 'is_valid' field to true.
   *
   * @param {GridValidRowModel} newRow - The new data for the row being updated.
   * @param {GridValidRowModel} oldRow - The existing data for the row before the update.
   * @returns {Object} The updated row data, including the validity status.
   */
  const processRowUpdate = React.useCallback(
    (newRow: GridValidRowModel, oldRow: GridValidRowModel) => {
      // Check if the newRow is valid, according to our validation rules
      let isInvalid = validateRow(newRow);

      const rows = apiRef.current?.getRowModels();

      let canSubmitDevices = true;

      for (const value of rows.values()) {
        // Iterate and check if the current row that we are editing is valid
        if (value.id === newRow.id) {
          if (!isValidDevice(newRow)) {
            canSubmitDevices = false;

            // Make sure to update the state of this row
            isInvalid = t('Error_Detected');
            break;
          }
        } else if (!isValidDevice(value)) {
          canSubmitDevices = false;
          break;
        }
      }

      // Please keep this logic the way it is. It seems to "work just fine".
      // Simplified the logic to set the canSubmitDevices state
      setCanSubmit(canSubmitDevices);

      // Simplified the logic to set the "is_valid" state
      // The row is valid if the "isInvalid" variable is null (doesn't contain a string error message).
      return { ...oldRow, ...newRow, is_valid: !isInvalid };
    },
    [t, apiRef, validateRow, isValidDevice]
  );

  /**
   * A memoized callback to load grid rows data from the local storage.
   * The grid rows are fetched based on the `publicId` of the site information.
   * If data is found in local storage, it parses and sets the rows using the `setRows` function.
   *
   * @callback loadGridRowsData
   * @returns {GridValidRowModel[]} An array of valid grid row models. Returns an empty array if no data is found in local storage.
   */
  const loadGridRowsData = React.useCallback(() => {
    // Set the rows from the retrieved state
    const sessionKey = `wizard-setup-${site.siteInfo.publicId}`;
    const loadedRows = localStorage.getItem(sessionKey + '_rows');
    if (loadedRows) {
      const parsedRows = JSON.parse(loadedRows) as GridValidRowModel[];
      setRows(parsedRows);
      return parsedRows;
    } else {
      return [];
    }
  }, [site.siteInfo.publicId, setRows]);

  React.useEffect(() => {
    if (gatewayLoading) {
      setIsLoading(true);
    } else {
      // Load the grid rows data from local storage
      const cachedRows = loadGridRowsData();

      // Getting rid of duplicate rows based on MAC address
      const parsedRows = formatDevices(devices);
      // Remove all the rows with duplicate MAC addresses
      const uniqueRows = parsedRows.filter(
        (value, index, self) => self.findIndex((t) => t.mac_addr === value.mac_addr) === index
      );
      // Combine the cached rows with the unique rows from the network call, preferring cached rows
      // This allows the user to "pick up where they left off" if they navigate away from the page
      const combinedRows = [
        ...cachedRows,
        ...uniqueRows.filter((row) => !cachedRows.some((cachedRow) => cachedRow.mac_addr === row.mac_addr))
      ];
      setRows(combinedRows);

      setIsLoading(false);
    }
  }, [devices, gatewayLoading, loadGridRowsData]);

  React.useEffect(() => {
    const updateMaxLength = () => {
      const inputElements = dataGridRef.current?.querySelectorAll('input.station-name');
      inputElements?.forEach((input) => {
        input.setAttribute('maxLength', '24');
      });
    };

    updateMaxLength();
  }, []);

  // For managing cached DataGrid state
  /**
   * Load the grid state from the local storage
   * Strictly a state restoration function
   */
  const loadGridState = React.useCallback(() => {
    if (!SAVE_DATA_GRID_STATE_TO_LOCAL_STORAGE) {
      return {};
    }

    const sessionKey = `wizard-setup-${site.siteInfo.publicId}`;
    const loadedState = localStorage.getItem(sessionKey);

    if (loadedState) {
      const parsedState = JSON.parse(loadedState) as GridInitialState;
      setInitialState(parsedState);
      return parsedState;
    } else {
      return {};
    }
  }, [site.siteInfo.publicId]);

  /**
   * This callback function saves the current state of the data grid to local storage.
   * It first checks if the flag SAVE_DATA_GRID_STATE_TO_LOCAL_STORAGE is enabled.
   * If enabled, it exports the current state of the data grid using the apiRef and stores it in local storage.
   * The state is stored under a session-specific key based on the site's public ID.
   *
   * Dependencies:
   * - `apiRef`: A reference to the data grid API to export the current state.
   * - `site.siteInfo.publicId`: The public ID of the site to uniquely identify the session.
   *
   * @returns {Object} The exported state of the data grid if available, otherwise an empty object.
   */
  const saveDataGridState = React.useCallback(() => {
    if (!SAVE_DATA_GRID_STATE_TO_LOCAL_STORAGE) return {};

    const exportedState = apiRef.current?.exportState();
    if (exportedState) {
      const sessionKey = `wizard-setup-${site.siteInfo.publicId}`;
      // Keep a key to identify the data for the session
      localStorage.setItem(sessionKey, JSON.stringify(exportedState));
      return exportedState;
    } else {
      return {};
    }
  }, [apiRef, site.siteInfo.publicId]);

  /**
   * Function to save the current state of grid rows data to both the application state and local storage.
   *
   * The function checks if the constant SAVE_DATA_GRID_STATE_TO_LOCAL_STORAGE is enabled. If not, it returns an empty object.
   * It retrieves the current row models from the grid using the `apiRef`, and if there are any rows, it constructs an array
   * of these rows and updates the application state using `setRows`. The function then saves the constructed array to local
   * storage with a key that includes the site's public ID.
   *
   * Dependencies:
   * - React: Requires React hook `useCallback` for memoization.
   * - apiRef: Reference to the grid's API for retrieving current row models.
   * - site: Contains site information including the public ID used in the local storage key.
   * - setRows: Function to update the rows in the application's state.
   *
   * Data Storage:
   * - The rows data is stored in local storage under the key `wizard-setup-${site.siteInfo.publicId}_rows`.
   *
   * @returns {Object|Array} The array of row data if rows exist; otherwise, an empty array or object.
   */
  const saveGridRowsData = React.useCallback(() => {
    if (!SAVE_DATA_GRID_STATE_TO_LOCAL_STORAGE) return {};
    const currentRowValues = apiRef.current?.getRowModels();
    if (currentRowValues) {
      const rowsArray: GridValidRowModel[] = [];
      // Iterate rows and set the state data
      for (const value of currentRowValues.values()) {
        rowsArray.push(value);
      }
      if (rowsArray.length === 0) {
        return [];
      }
      setRows(rowsArray);
      // Save to local storage
      const sessionKey = `wizard-setup-${site.siteInfo.publicId}`;
      localStorage.setItem(sessionKey + '_rows', JSON.stringify(rowsArray));
      return rowsArray;
    } else {
      return [];
    }
  }, [apiRef, site.siteInfo.publicId, setRows]);

  React.useLayoutEffect(() => {
    if (SAVE_DATA_GRID_STATE_TO_LOCAL_STORAGE) {
      loadGridState();
      loadGridRowsData();
      // restoreGridState();
      // Set the rows from the retrieved state

      // Handle refresh and navigating away/refreshing the page
      window.addEventListener('beforeunload', saveDataGridState);
      window.addEventListener('beforeunload', saveGridRowsData);

      // Handle back and forward navigation
      window.addEventListener('popstate', saveDataGridState);
      window.addEventListener('popstate', saveGridRowsData);

      // Cleanup
      return () => {
        // Handle hard refresh
        window.removeEventListener('beforeunload', saveDataGridState);
        window.removeEventListener('beforeunload', saveGridRowsData);

        // Handle back and forward navigation
        window.removeEventListener('popstate', saveDataGridState);
        window.removeEventListener('popstate', saveGridRowsData);
        saveDataGridState();
        saveGridRowsData();
      };
    }
  }, [loadGridRowsData, loadGridState, saveDataGridState, saveGridRowsData]);

  if (isSaving) {
    return (
      <LoadingProgressStepCounter
        currentStep={currentStep}
        totalSteps={countTotalSteps}
        message={registrationStatusMessage}
      />
    );
  } else if (isLoading || gwLoading) {
    return <Spinner />;
  } else {
    // Note: There will be an inspection error for "xxl" not being a valid Breakpoint value because it is not defined in
    // the Breakpoint enum. This is a known issue, and we can safely ignore it because we need this "xxl" value.
    return (
      <Container maxWidth={'xl'} sx={{ width: '90%' }}>
        <Box className={'flex flex-col my_20px'}>
          <Box className={'flex flex-col'}>
            <h2>{t('Assign_Station_Names')}</h2>
            <p>{t('Review_and_Make_Adjustments')}</p>
            <Box maxHeight={600} className={'flex flex-col'}>
              <DataGrid
                initialState={{ ...initialState }}
                apiRef={apiRef}
                ref={dataGridRef}
                processRowUpdate={processRowUpdate}
                columns={columns}
                rows={rows}
                getRowClassName={(params) => {
                  if (params.row.is_valid) {
                    return 'row-success';
                  } else {
                    return 'row-needs-editing';
                  }
                }}
                columnVisibilityModel={{
                  id: false,
                  is_valid: false,
                  firmware_version: false,
                  device_type: false,
                  row_is_editable: false
                }}
                editMode={'cell'}
                cellModesModel={cellModesModel}
                onCellModesModelChange={handleCellModesModelChange}
                onCellClick={handleCellClick}
                getRowId={(row) => row.id}
              />
            </Box>
          </Box>
          <Box className={'flex flex-row justify-between items-center w-full mt-2'}>
            <Button onClick={props.handlePreviousStep} variant={'contained'} color={'primary'}>
              {t('Button_Back')}
            </Button>
            {error && <span className={'text-red text-center text-sm mx_24px'}>{error}</span>}
            <Button
              variant={'contained'}
              color={'primary'}
              onClick={async () => await handleSaveAndContinue()}
              disabled={!canSubmit}
            >
              {t('Continue')}
            </Button>
          </Box>
        </Box>
      </Container>
    );
  }
};

export default DeviceConfigurationGrid;

import { Status } from 'shared/utils/Status';
import { Cached, Memory } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import CardComponent from './CardComponent';
import { useSiteContext } from '../SiteContext/SiteContext';

/**
 * Gateway Firmware Card Component
 * @returns Gateway Firmware Card Component
 */
export const GatewayFirmwareCard = () => {
  const { t } = useTranslation();
  const { gwOnlineStatus, hasEditPermission, setGWFirmwareDialogOpen, isGWRegistered } = useSiteContext();

  return (
    <CardComponent
      icon={<Memory />}
      title={t('Gateway_Firmware')}
      descriptions={
        isGWRegistered && gwOnlineStatus === Status.Online
          ? (t('Gateway_Update_Firmware_Descriptions', { returnObjects: true }) as string[])
          : gwOnlineStatus === Status.Updating
          ? (t('Gateway_Updating_Firmware_Descriptions', { returnObjects: true }) as string[])
          : (t('Gateway_Update_Firmware_Required', { returnObjects: true }) as string[])
      }
      buttonText={t('Update_Firmware_Button')}
      onClick={() => {
        setGWFirmwareDialogOpen(true);
      }}
      buttonIcon={<Cached />}
      isButtonEnabled={isGWRegistered && gwOnlineStatus === Status.Online}
      hasError={!(isGWRegistered && gwOnlineStatus === Status.Online)}
      hasEditPermission={hasEditPermission}
    />
  );
};

export default GatewayFirmwareCard;

import { useSelector } from 'react-redux';
import { RootState } from 'store';
import MultiTenantSiteWizard from './MultiTenantSiteWizard';
import CommercialSiteWizard from './CommercialSiteWizard';
import { useEffect, useState } from 'react';

const SetupWizard = () => {
  const [isRegistered, setIsRegistered] = useState(false);
  const siteData = useSelector((state: RootState) => state.site.siteInfo);

  useEffect(() => {
    if (siteData.registeredGatewayPublicId) {
      setIsRegistered(true);
    }
  }, [siteData]);

  if (siteData.typeId === 1) {
    return <CommercialSiteWizard isRegistered={isRegistered} setIsRegistered={setIsRegistered} />;
  } else {
    return <MultiTenantSiteWizard isRegistered={isRegistered} setIsRegistered={setIsRegistered} />;
  }
};

export default SetupWizard;

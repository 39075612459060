import { Status } from 'shared/utils/Status';
import CloudFilledIcon from '@mui/icons-material/Cloud';
import { Cached } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import CardComponent from './CardComponent';
import { useSiteContext } from '../SiteContext/SiteContext';

/**
 * Gateway Status Card Component
 * @returns Gateway Status Card Component
 */
const GatewayStatusCard = () => {
  const { t } = useTranslation();
  const { gwOnlineStatus, hasEditPermission, getGatewayOnlineStatus } = useSiteContext();

  return (
    <CardComponent
      icon={<CloudFilledIcon />}
      title={t('Gateway_Status')}
      descriptions={
        gwOnlineStatus === Status.Online
          ? (t('Gateway_Online_Descriptions', { returnObjects: true }) as string[])
          : gwOnlineStatus === Status.Unknown || gwOnlineStatus === Status.Offline || gwOnlineStatus === Status.Error
          ? (t('Check_Gateway_Status', { returnObjects: true }) as string[])
          : gwOnlineStatus === Status.Checking
          ? (t('Gateway_Checking_Descriptions', { returnObjects: true }) as string[])
          : (t('Gateway_Offline_Descriptions', { returnObjects: true }) as string[])
      }
      buttonText={t('Check_Gateway_Status')}
      isButtonEnabled={
        gwOnlineStatus === Status.Online ||
        gwOnlineStatus === Status.Unknown ||
        gwOnlineStatus === Status.Offline ||
        gwOnlineStatus === Status.Error
      }
      onClick={getGatewayOnlineStatus}
      buttonIcon={<Cached />}
      hasError={
        !(
          gwOnlineStatus === Status.Online ||
          gwOnlineStatus === Status.Unknown ||
          gwOnlineStatus === Status.Offline ||
          gwOnlineStatus === Status.Error
        )
      }
      hasEditPermission={hasEditPermission}
    />
  );
};

export default GatewayStatusCard;

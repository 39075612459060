import { FC } from 'react';
import { Box, Typography, FormControl, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { useTranslation } from 'react-i18next';

/**
 * Allows the user to select from a radio group whether they are using SIM or not
 *
 * @param param0
 * @returns
 */
const ConnectionTypeSelection: FC<{
  isUsingSim: boolean;
  setIsUsingSim: (isUsingSim: boolean) => void;
  gatewayModel: string;
}> = ({ isUsingSim, setIsUsingSim, gatewayModel }) => {
  const { t } = useTranslation();

  const handleConnectionTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsUsingSim(event.target.value === 'true');
  };

  return (
    <Box>
      <Typography variant="wizardSectionHeader">{t('Internet_Or_SIM')}</Typography>
      <Box sx={{ mb: 2 }}>
        <FormControl disabled={gatewayModel === '1'}>
          <RadioGroup
            name="internetOrSim"
            aria-label="Internet or SIM"
            value={isUsingSim.toString()}
            onChange={handleConnectionTypeChange}
          >
            <FormControlLabel value="false" control={<Radio />} label={t('Internet_Or_SIM_False')} />
            <FormControlLabel value="true" control={<Radio />} label={t('Internet_Or_SIM_True')} />
          </RadioGroup>
        </FormControl>
      </Box>
    </Box>
  );
};

export default ConnectionTypeSelection;

import { Box, Typography, Grid, TextField } from '@mui/material';
import { t } from 'i18next';
import { FC } from 'react';
import { AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT } from 'shared/constantAwsApi';
import { formatToMACAddress } from 'shared/utils/helperFunctions';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import GatewayErrorBox from '../GatewayErrorBox';
import CheckIcon from '@mui/icons-material/Check';
import { LoadingButton } from '@mui/lab';

const GatewayMacInput: FC<{
  inputMacAddress: string;
  setInputMacAddress: (inputMacAddres: string) => void;
  isGatewayOnline: boolean;
  gatewayHasError: boolean;
  gatewayErrorCode: string;
  gatewayErrorMessage: string;
  existingId: string;
  existingPw: string;
  setExistingId: (existingId: string) => void;
  setExistingPw: (existingPw: string) => void;
  isLoading: boolean;
  isUsingSim: boolean;
  handleEnableSIMSite: () => void;
  isRegistered: boolean;
  macAddressConfirmed: boolean;
}> = ({
  inputMacAddress,
  setInputMacAddress,
  isGatewayOnline,
  gatewayHasError,
  gatewayErrorCode,
  gatewayErrorMessage,
  existingId,
  existingPw,
  setExistingId,
  setExistingPw,
  isLoading,
  isUsingSim,
  handleEnableSIMSite,
  isRegistered,
  macAddressConfirmed
}) => {
  const handleMacAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputMacAddress(formatToMACAddress(event.target.value));
  };

  return (
    <Box sx={{ my: 2 }}>
      <Typography variant="wizardSectionHeader" gutterBottom>
        {t('Enter_Gateway_MAC_Address')}
      </Typography>
      <Typography variant="body1" gutterBottom>
        {t('Enter_Gateway_MAC_Address_Desc')}
      </Typography>
      <Grid container spacing={2} alignItems="center" sx={{ my: 2 }}>
        <Grid item xs={12} sm={12} md={4} lg={3}>
          <Box
            component="img"
            sx={{
              width: '188px',
              height: '92px',
              radius: '4px'
            }}
            src={`${AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT}/IXGW-Mac-Location.png`}
            alt="Image of location of IXGW-TGW MAC Address"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <Box sx={{ display: 'block', mb: 2 }}>
            <TextField
              id="macAddress"
              label={t('Enter_Gateway_MAC_Address_Label')}
              value={inputMacAddress}
              disabled={isGatewayOnline || isRegistered}
              onChange={handleMacAddressChange}
              InputLabelProps={{ shrink: true }}
              InputProps={{ endAdornment: isGatewayOnline && <CheckCircleIcon sx={{ color: 'primary.main' }} /> }}
              sx={{ width: '500px' }}
            />
          </Box>
          {isUsingSim && !macAddressConfirmed && (
            <Box sx={{ my: 2 }}>
              <LoadingButton
                variant="outlined"
                sx={{ width: '500px' }}
                onClick={handleEnableSIMSite}
                loading={isLoading}
              >
                {t('Button_Confirm_Mac_Address')}
              </LoadingButton>
            </Box>
          )}
          {isUsingSim && macAddressConfirmed && (
            <Box
              sx={{
                padding: '4px',
                border: '1px solid #2E7D32',
                borderRadius: '4px',
                display: 'flex',
                gap: '10px',
                width: '500px',
                justifyContent: 'center',
                mb: 2
              }}
            >
              <CheckIcon sx={{ color: '#2E7D32' }} />
              <Typography variant="success" gutterBottom>
                {t('Gateway_MAC_Confirmed')}
              </Typography>
            </Box>
          )}
          {gatewayHasError && (
            <GatewayErrorBox
              gatewayErrorCode={gatewayErrorCode}
              gatewayErrorMessage={gatewayErrorMessage}
              existingId={existingId}
              existingPw={existingPw}
              setExistingId={setExistingId}
              setExistingPw={setExistingPw}
              isLoading={isLoading}
            />
          )}
          {isRegistered && (
            <Box
              sx={{
                padding: '14px 16px 14px 16px',
                border: '1px solid #2E7D32',
                borderRadius: '4px',
                opacity: '0px',
                display: 'flex',
                gap: '10px',
                width: '500px'
              }}
            >
              <CheckCircleIcon sx={{ color: '#2E7D32' }} />
              <Typography variant="success" gutterBottom>
                {t('Gateway_Registered')}
              </Typography>
            </Box>
          )}
        </Grid>
      </Grid>
      <Typography variant="dialogSubTitle" gutterBottom>
        {t('Enter_Gateway_MAC_Address_Note1')}
      </Typography>
      {/* TODO: Removing until tech services can create a article for this */}
      {/* <Typography variant="dialogSubTitle" gutterBottom>
        {t('Enter_Gateway_MAC_Address_Note2')}
      </Typography> */}
    </Box>
  );
};

export default GatewayMacInput;

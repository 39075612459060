// SiteContext.tsx
// Holds shared state and functions for the Site page
import React, { createContext, useContext, useState, ReactNode, Dispatch, SetStateAction, useCallback } from 'react';
import { RowElem } from '../Grid/SiteGrid';
import { Status } from 'shared/utils/Status';
import { usePermission } from 'context/PermissionContext';
import { useDispatch, useSelector } from 'react-redux';
import { getSite } from 'store/slices/siteSlice';
import { PermissionsContextType } from 'permissions/utils';
import { RootState } from 'store';
import { fetchGatewayCommand } from 'shared/rmGateway/gwCommandProcessor';
import { useHandleGatewayCommandMutation, useUpdateDeviceMutation } from 'services/aiphoneCloud';
import { gwCommand } from 'shared/rmGateway/gwCommand';
import { useTranslation } from 'react-i18next';
import { getGWErrorCode } from 'shared/rmGateway/gwErrorHandler';
import { IDevice } from 'store/slices/devicesSlice';
import { getDeviceModelNumberFromModelType } from 'shared/utils/helperFunctions';
import { checkCurrentFirmwareStatus, checkFirmwareSeries } from 'shared/utils/firmwareFunctions';
import { getDeviceStatus } from '../Utils/SiteUtil';

interface SiteContextProps {
  rows: RowElem[];
  setRows: Dispatch<SetStateAction<RowElem[]>>;
  gwOnlineStatus: Status;
  setGwOnlineStatus: Dispatch<SetStateAction<Status>>;
  hasEditPermission: boolean;
  isGWFirmwareDialogOpen: boolean;
  setGWFirmwareDialogOpen: Dispatch<SetStateAction<boolean>>;
  gwStatusMessage: string;
  setGwStatusMessage: Dispatch<SetStateAction<string>>;
  isGWRegistered: boolean;
  setIsGatewayRegistered: Dispatch<SetStateAction<boolean>>;
  isGatewayUnregistered: boolean;
  setIsGatewayUnregistered: Dispatch<SetStateAction<boolean>>;
  syncStatus: Status;
  setSyncStatus: Dispatch<SetStateAction<Status>>;
  latestFirmwareList: any;
  setLatestFirmwareList: Dispatch<SetStateAction<any>>;
  errorMessage: string | null;
  setErrorMessage: Dispatch<SetStateAction<string | null>>;
  successMessage: string | null;
  setSuccessMessage: Dispatch<SetStateAction<string | null>>;
  showAlert: boolean;
  setShowAlert: Dispatch<SetStateAction<boolean>>;
  showIXGSync: boolean;
  setShowIXGSync: Dispatch<SetStateAction<boolean>>;
  isLoginDialogOpen: boolean;
  setIsLoginDialogOpen: Dispatch<SetStateAction<boolean>>;
  isSyncDialogOpen: boolean;
  setIsSyncDialogOpen: Dispatch<SetStateAction<boolean>>;
  syncDialogTitle: string;
  setSyncDialogTitle: Dispatch<SetStateAction<string>>;
  syncDialogContent: string;
  setSyncDialogContent: Dispatch<SetStateAction<string>>;
  getGatewayOnlineStatus: () => Promise<void>;
  buildDeviceRow: (devices: IDevice[]) => RowElem[];
}

const SiteContext = createContext<SiteContextProps | undefined>(undefined);

/**
 * Custom hook to use the SiteContext
 * @returns SiteContextProps
 */
export const useSiteContext = () => {
  const context = useContext(SiteContext);
  if (!context) {
    throw new Error('useSiteContext must be used within a SiteProvider');
  }
  return context;
};

/**
 * SiteProvider contect wrapper component
 * @param children
 * @returns JSX.Element
 */
export const SiteProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const site = useSelector(getSite);
  const { isAllowedTo } = usePermission();
  const hasEditPermission = isAllowedTo('site:edit', site.siteInfo.publicId, PermissionsContextType.SITE);
  const gateway = useSelector(
    (state: RootState) => state.devices.DeviceList[site?.siteInfo?.registeredGatewayPublicId]
  );
  const { DeviceList: devices } = useSelector((state: RootState) => state.devices);
  const dispatch = useDispatch();
  const [updateDevice] = useUpdateDeviceMutation();
  const [handleGatewayCommand] = useHandleGatewayCommandMutation();
  const { t } = useTranslation();

  /** Setting up the possible gateway credentials */
  const possibleGwCredentials = [
    {
      gwId: gateway?.basicInfo?.memoId,
      gwPassword: gateway?.basicInfo?.memoPass
    },
    {
      gwId: gateway?.basicInfo?.adminId,
      gwPassword: gateway?.basicInfo?.adminPass
    },
    {
      gwId: 'admin',
      gwPassword: 'admin'
    }
  ];

  /** Setting up the shared state of the Site page */
  const [rows, setRows] = useState<RowElem[]>([]);
  const [gwOnlineStatus, setGwOnlineStatus] = useState(Status.Unknown);
  const [isGWFirmwareDialogOpen, setGWFirmwareDialogOpen] = useState(false);
  const [gwStatusMessage, setGwStatusMessage] = useState('');
  const [isGWRegistered, setIsGatewayRegistered] = useState(true);
  const [isGatewayUnregistered, setIsGatewayUnregistered] = useState(false);
  const [syncStatus, setSyncStatus] = useState(Status.StandBy);
  const [latestFirmwareList, setLatestFirmwareList] = useState<any>({});
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [showAlert, setShowAlert] = useState(false);
  const [showIXGSync, setShowIXGSync] = useState(false);
  const [isLoginDialogOpen, setIsLoginDialogOpen] = useState(false);
  const [isSyncDialogOpen, setIsSyncDialogOpen] = useState(false);
  const [syncDialogTitle, setSyncDialogTitle] = useState('');
  const [syncDialogContent, setSyncDialogContent] = useState('');

  /** Send command to gateway */
  const sendCommandAndFetchResult = async (credentials) => {
    if (!gateway) {
      return null;
    }

    const gatewayInfo = {
      awsPropertyId: site?.siteInfo?.awsPropertyId,
      gwMacAddress: gateway?.basicInfo?.macAddress,
      gwId: credentials.gwId,
      gwPassword: credentials.gwPassword,
      gwIpAddress: gateway?.networkSettings?.ipV4Address || ''
    };

    try {
      // send command to gateway via ioT
      const ioTPayload = fetchGatewayCommand('sendCommand', gwCommand.STATION_SEARCH, gatewayInfo, null, null);
      if (ioTPayload === 'Missing information' || gateway?.basicInfo?.macAddress === undefined) {
        throw new Error('Missing information');
      }
      await handleGatewayCommand(ioTPayload).unwrap();

      await new Promise((resolve) => setTimeout(resolve, 10000));

      // fetch the result from the gateway
      const fetchPayload = fetchGatewayCommand('fetchResult', gwCommand.STATION_SEARCH, gatewayInfo, null, null);
      const fetchResponse = await handleGatewayCommand(fetchPayload).unwrap();

      return fetchResponse;
    } catch (error) {
      setErrorMessage(t(getGWErrorCode({ message: 'Gateway_status_error' })));
      setIsSyncDialogOpen(false);
      return null;
    }
  };

  /** Part of the Gateway Status check logic */
  const checkStatusWithStationSearch = async () => {
    const systemId = site?.siteInfo?.systemId;
    const systemPassword = site?.siteInfo?.systemPassword;
    let fetchResponse;
    let statusCode;

    for (const credentials of possibleGwCredentials) {
      fetchResponse = await sendCommandAndFetchResult(credentials);
      if (fetchResponse) {
        statusCode = fetchResponse?.statusCode.slice(0, 3);
        if (statusCode === '200') {
          break;
        } else if (statusCode === '406') {
          setGwOnlineStatus(Status.Busy);
          return;
        } else if (statusCode === '402') {
          continue;
        }
      }
    }
    setIsSyncDialogOpen(false);

    if (statusCode === '200') {
      setGwOnlineStatus(Status.Online);
      // Process results from Search
      // Check the payload for the Gateway's firmware version and IP address
      const gatewayInfoFromResponse = fetchResponse.payload.find(
        (device: { mac_addr: string }) => device.mac_addr === gateway?.basicInfo.macAddress
      );
      const gwFirmwareVersion = gatewayInfoFromResponse.fw_ver;
      const gwIPAddress = gatewayInfoFromResponse.ip_addr;

      // compare the results to what we have saved for the gateway
      if (gateway?.basicInfo?.firmwareVersion === null || gwFirmwareVersion !== gateway?.basicInfo?.firmwareVersion) {
        const updatedDeviceParams = {
          device: {
            publicId: site.siteInfo?.registeredGatewayPublicId,
            basicInfo: {
              firmwareVersion: gwFirmwareVersion
            }
          }
        };
        dispatch(updateDevice(updatedDeviceParams));
      }
      if (gwIPAddress !== gateway?.networkSettings?.ipV4Address) {
        const updatedDeviceParams = {
          device: {
            publicId: site.siteInfo?.registeredGatewayPublicId,
            networkSettings: {
              ipV4Address: gwIPAddress
            }
          }
        };
        dispatch(updateDevice(updatedDeviceParams));
      }
      // loop through the other devices in the site
      for (const device of Object.values(devices)) {
        const deviceInfo = {
          deviceIpAddress: device?.networkSettings?.ipV4Address,
          deviceMacAddress: device?.basicInfo.macAddress,
          deviceStationNumber: device?.basicInfo.stationNumber,
          deviceId: systemId ? systemId : device?.basicInfo.adminId,
          devicePassword: systemPassword ? systemPassword : device?.basicInfo.adminPass
        };
        const deviceInfoFromResponse = fetchResponse.payload.find(
          (device: { mac_addr: string }) => device.mac_addr === deviceInfo.deviceMacAddress
        );
        if (deviceInfoFromResponse === undefined) {
          continue;
        }

        // check firmware info
      }
    }
  };

  /**
   * check the connection of the gateway (online / offline)
   * check the gateway is register to current site
   */
  const checkIoTConnection = async (macAddress: string) => {
    const awsSiteId = site?.siteInfo?.awsPropertyId;
    try {
      const payload = {
        action: 'checkConnection',
        payload: {
          mac_addr: macAddress
        }
      };
      const response = await handleGatewayCommand(payload).unwrap();

      if (!response.gateway_is_connected) {
        if (response.disconnect_reason === 'MQTT_KEEP_ALIVE_TIMEOUT') {
          setGwOnlineStatus(Status.Offline);
        }
        throw new Error(response.disconnect_reason);
      }

      // if the response includes the site_id, confirm it is the same as the current aws site
      if (response?.site_id !== awsSiteId) {
        throw {
          message: 'Gateway_Already_Registered_To_Site',
          site_id: response.site_id
        };
      }

      if (response && response.gateway_is_connected) {
        setGwStatusMessage(t('Gateway_Status_Check_Complete'));
        setGwOnlineStatus(Status.Online);
      }
    } catch (error) {
      setIsSyncDialogOpen(false);
      if (error.message === 'Gateway_Already_Registered_To_Site') {
        const customError = error as unknown as { message: string; site_id: string };
        setErrorMessage(`${t(`Gateway_Error_message.${customError.message}`)} # ${customError.site_id}`);
        return;
      }
      setErrorMessage(t(getGWErrorCode(error)));
    }
  };

  /** Checking the gateway status */
  const getGatewayOnlineStatus = useCallback(async () => {
    let dialogTitle, dialogContent;
    if (gwOnlineStatus === Status.Busy || gwOnlineStatus === Status.Syncing || gwOnlineStatus === Status.Updating) {
      return;
    }

    setGwOnlineStatus(Status.Checking);
    // check if gateway online
    dialogTitle = t('Gateway_Status_Check');
    dialogContent = t('Gateway_Status_Check_Content');
    setSyncDialogTitle(dialogTitle);
    setSyncDialogContent(dialogContent);
    setIsSyncDialogOpen(true);
    await new Promise((resolve) => setTimeout(resolve, 5000));
    await checkIoTConnection(gateway?.basicInfo?.macAddress);
    setSyncDialogContent('');

    await new Promise((resolve) => setTimeout(resolve, 3000));

    setGwStatusMessage('');
    // Only do the station search if the gateway is online
    if (gwOnlineStatus === Status.Offline) return;

    dialogTitle = t('Gateway_StationSearch_Title');
    dialogContent = t('Gateway_StationSearch_Content');
    setSyncDialogTitle(dialogTitle);
    setSyncDialogContent(dialogContent);
    await checkStatusWithStationSearch();
    setShowAlert(true);
    setSuccessMessage(t('Success'));
    setSuccessMessage(t('Gateway_Status_Check_Done'));
  }, [gateway, gwOnlineStatus, t]);

  // check each device for the last device.networkSettings.lastSyncedOn and if it is null, set to 'Standby' otherwise, set to 'Synced'
  const buildDeviceRow = useCallback(
    (devices: IDevice[]): RowElem[] => {
      return Object.entries(devices).map(([, device]: [string, IDevice]) => ({
        id: device.basicInfo.devicePublicId,
        MacAddress: device.basicInfo.macAddress,
        StationNumber: device.basicInfo.stationNumber,
        StationName: device.basicInfo.stationName,
        ModelNumber: getDeviceModelNumberFromModelType(device.basicInfo.deviceModel, device.basicInfo.deviceType),
        FirmwareSeries: checkFirmwareSeries(device?.basicInfo?.firmwareVersion) || t('Unknown'),
        FirmwareStatus: checkCurrentFirmwareStatus(device, latestFirmwareList) || t('Unknown'),
        Status: getDeviceStatus(device),
        ConfigFileUrl: '-'
      }));
    },
    [latestFirmwareList, t]
  );

  return (
    <SiteContext.Provider
      value={{
        rows,
        setRows,
        gwOnlineStatus,
        setGwOnlineStatus,
        hasEditPermission,
        isGWFirmwareDialogOpen,
        setGWFirmwareDialogOpen,
        gwStatusMessage,
        setGwStatusMessage,
        isGWRegistered,
        setIsGatewayRegistered,
        isGatewayUnregistered,
        setIsGatewayUnregistered,
        syncStatus,
        setSyncStatus,
        latestFirmwareList,
        setLatestFirmwareList,
        errorMessage,
        setErrorMessage,
        successMessage,
        setSuccessMessage,
        showAlert,
        setShowAlert,
        showIXGSync,
        setShowIXGSync,
        isLoginDialogOpen,
        setIsLoginDialogOpen,
        isSyncDialogOpen,
        setIsSyncDialogOpen,
        syncDialogTitle,
        setSyncDialogTitle,
        syncDialogContent,
        setSyncDialogContent,
        getGatewayOnlineStatus,
        buildDeviceRow
      }}
    >
      {children}
    </SiteContext.Provider>
  );
};

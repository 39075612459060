import { Status } from 'shared/utils/Status';
import { IDevice } from 'store/slices/devicesSlice';
import { Cached, DeviceHub } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import CardComponent from './CardComponent';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { getSite } from 'store/slices/siteSlice';
import { RootState } from 'store';
import { RowElem } from '../Grid/SiteGrid';
import { useCreateConfigFileMutation } from 'services/aiphoneCloud';
import { useSiteContext } from '../SiteContext/SiteContext';

const FIRMWARE_VERSION_REQUIRED = 3.81;

/**
 * Gateway Configuration Card Component Props
 * @interface GatewayCongiurationCardProps
 * @param {IDevice} gateway - Gateway Device
 * @param {function} handleConfigFileUpload - Function to handle config file upload
 */
export interface GatewayCongiurationCardProps {
  gateway: IDevice;
  handleConfigFileUpload: (device: RowElem) => Promise<Status.Synced | Status.Error | undefined>;
}

/**
 * Gateway Configuration Card Component
 * @param {GatewayCongiurationCardProps} props - Gateway Configuration Card Component Props
 * @returns Gateway Configuration Card Component
 */
const GatewayConfigurationCard = ({ gateway, handleConfigFileUpload }: GatewayCongiurationCardProps) => {
  const {
    rows,
    setRows,
    gwOnlineStatus,
    setGwOnlineStatus,
    hasEditPermission,
    isGWRegistered,
    setSyncStatus,
    setSyncDialogTitle,
    setSyncDialogContent,
    setIsSyncDialogOpen
  } = useSiteContext();
  const { t } = useTranslation();
  const site = useSelector(getSite);
  const { DeviceList: devices } = useSelector((state: RootState) => state.devices);
  const [createConfigFile] = useCreateConfigFileMutation();

  /** Handle the sync gateway button */
  const handleSyncGatewayButton = useCallback(async () => {
    // Fetch list of config files, but filter out non-gateway devices
    const createConfigFilesData = {
      sitePublicId: site.siteInfo.publicId,
      devicePublicIds: Object.entries(devices)
        .filter(([, device]: [string, IDevice]) => device.basicInfo.deviceType === 18)
        .map(([, device]: [string, IDevice]) => device.publicId)
    };
    // Verify we have at least one gateway
    if (createConfigFilesData.devicePublicIds.length === 0) {
      throw new Error('No devices found');
    }
    // set the gateway's row as busy
    const dialogTitle = t('Gateway_GatewaySync_Title');
    const dialogContent = t('Gateway_GatewaySync_Content');
    setSyncDialogTitle(dialogTitle);
    setSyncDialogContent(dialogContent);
    setIsSyncDialogOpen(true);
    setGwOnlineStatus(Status.Busy);
    setSyncStatus(Status.Waiting);

    // Generate config files and upload
    try {
      const newRows = JSON.parse(JSON.stringify(rows));
      for (const row of newRows) {
        if (devices[row.id].basicInfo.deviceType == 18) {
          const response = await createConfigFile({
            sitePublicId: site.siteInfo.publicId,
            devicePublicId: row.id
          });

          if ('data' in response) {
            row.ConfigFileUrl = response.data;
            row.Status = Status.Syncing;
            setRows(newRows);
            const gatewaySyncResponse = await handleConfigFileUpload(row);
            if (gatewaySyncResponse !== void 0) {
              row.Status = gatewaySyncResponse;
              setRows(newRows);
            }
          } else {
            throw new Error('Failed to create config files');
          }
        }
      }
    } catch (error) {
      setSyncStatus(Status.Error);
    }
    setIsSyncDialogOpen(false);
    setGwOnlineStatus(Status.Online);
  }, [rows, site, devices, handleConfigFileUpload, t]);

  return (
    <CardComponent
      icon={<DeviceHub />}
      title={t('Gateway_Configuration')}
      descriptions={
        !gateway?.basicInfo?.firmwareVersion || gateway?.basicInfo?.firmwareVersion < FIRMWARE_VERSION_REQUIRED
          ? (t('Gateway_Firmware_Update_Required', { returnObjects: true }) as string[])
          : gateway?.lastSyncedOn
          ? (t('Gateway_Configuration_File_Synced', { returnObjects: true }) as string[])
          : (t('Gateway_Sync_Configuration', { returnObjects: true }) as string[])
      }
      buttonText={t('Sync_Gateway_Button')}
      onClick={handleSyncGatewayButton}
      buttonIcon={<Cached />}
      isButtonEnabled={
        isGWRegistered &&
        gwOnlineStatus === Status.Online &&
        gateway?.basicInfo?.firmwareVersion >= FIRMWARE_VERSION_REQUIRED
      }
      hasError={
        !(
          isGWRegistered &&
          gwOnlineStatus === Status.Online &&
          gateway?.basicInfo?.firmwareVersion >= FIRMWARE_VERSION_REQUIRED
        )
      }
      hasEditPermission={hasEditPermission}
    />
  );
};

export default GatewayConfigurationCard;

import { Dialog } from '@mui/material';
import LoginPanel from '../../../Billing/components/MobileService/LoginPanel';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useSiteContext } from '../SiteContext/SiteContext';

/**
 * interface for LoginDialogProps to LoginDialog
 * @interface LoginDialogProps
 * @property {() => void} handleClose - function to close the dialog
 */
export interface LoginDialogProps {
  handleClose: () => void;
}

/**
 * LoginDialog component
 * @param {LoginDialogProps} props - props object
 * @returns {JSX.Element}
 */
const LoginDialog = ({ handleClose }: LoginDialogProps) => {
  const dispatch = useDispatch();
  const { isLoginDialogOpen, setIsLoginDialogOpen } = useSiteContext();

  const setUsernameAndToken = useCallback(
    async (userName: string, receivedToken: string) => {
      localStorage.setItem('acltoken', receivedToken);
      localStorage.setItem('aclUserName', userName);
      setTimeout(() => {
        setIsLoginDialogOpen(false);
      }, 3000);
    },
    [dispatch]
  );

  return (
    <Dialog open={isLoginDialogOpen} onClose={handleClose} fullWidth maxWidth="sm">
      <LoginPanel
        handleClose={handleClose}
        setUsernameAndToken={setUsernameAndToken}
        setIsLoginDialogOpen={setIsLoginDialogOpen}
      />
    </Dialog>
  );
};

export default LoginDialog;

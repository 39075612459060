import { Box, Button, Checkbox, FormControlLabel, TextField, Typography } from '@mui/material';
import { FC, useState } from 'react';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { t } from 'i18next';

const GatewayErrorBox: FC<{
  gatewayErrorMessage: string;
  gatewayErrorCode: string;
  existingId: string;
  existingPw: string;
  setExistingId: (existingId: string) => void;
  setExistingPw: (existingPw: string) => void;
  isLoading: boolean;
}> = ({ gatewayErrorMessage, gatewayErrorCode, existingId, existingPw, setExistingId, setExistingPw, isLoading }) => {
  const [unkownIdPw, setUnknownIdPw] = useState(false);
  const [_, setResetComplete] = useState(false);

  return (
    <Box
      sx={{
        width: '500px',
        minHeight: '74px',
        padding: '14px 16px 14px 16px',
        border: '1px solid #D32F2F',
        borderRadius: '4px',
        opacity: '0px'
      }}
    >
      <Box sx={{ display: 'flex', gap: '8px' }}>
        <Box sx={{ display: 'block' }}>
          <ErrorOutlineIcon sx={{ color: 'red.main' }} />
        </Box>
        <Typography variant="gatewayError">{gatewayErrorMessage}</Typography>
      </Box>
      {gatewayErrorCode === '420' && (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
          <Typography variant="body1">{t('Gateway_ID_and_PW')}</Typography>
          {unkownIdPw ? (
            <Box
              sx={{
                backgroundColor: '#2196F30A',
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
                padding: '10px'
              }}
            >
              <Typography variant="body1">{t('Gateway_Reset_Instructions.Title')}</Typography>
              <Typography variant="listItem">{t('Gateway_Reset_Instructions.Step1')}</Typography>
              <Typography variant="listItem">{t('Gateway_Reset_Instructions.Step2')}</Typography>
              <Typography variant="listItem">{t('Gateway_Reset_Instructions.Step3')}</Typography>
              <Typography variant="listItem">{t('Gateway_Reset_Instructions.Step4')}</Typography>
              <Button
                onClick={() => {
                  setResetComplete(true);
                }}
                sx={{ alignSelf: 'flex-start' }}
              >
                {t('Gateway_Reset_Complete')}
              </Button>
            </Box>
          ) : (
            <>
              <TextField
                id="existing-id"
                value={existingId}
                onChange={(e) => setExistingId(e.target.value)}
                label={t('Gateway_ID')}
                variant="outlined"
                fullWidth
                disabled={isLoading}
              />
              <TextField
                id="existing-pw"
                value={existingPw}
                onChange={(e) => setExistingPw(e.target.value)}
                label={t('Gateway_Password')}
                variant="outlined"
                fullWidth
                disabled={isLoading}
              />
            </>
          )}
          <FormControlLabel
            control={
              <Checkbox
                value={unkownIdPw}
                onChange={(e) => {
                  setUnknownIdPw(e.target.checked);
                }}
              />
            }
            label={t('Gateway_ID_PW_Unknown')}
          />
        </Box>
      )}
    </Box>
  );
};

export default GatewayErrorBox;

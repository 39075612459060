import { Box, Button, Typography } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { FC, useState } from 'react';
import { t } from 'i18next';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { getSiteSubscriptionStatus } from 'shared/api/Aws/SimBillingApi';
import { LoadingButton } from '@mui/lab';

const GatewaySIMInfo: FC<{
  macAddressConfirmed: boolean;
  hasSIMSubscription: boolean;
  setHasSIMSubscription: (hasSIMSubscription: boolean) => void;
}> = ({ macAddressConfirmed, hasSIMSubscription, setHasSIMSubscription }) => {
  const [isCheckingSIMStatus, setIsCheckingSIMStatus] = useState(false);
  const siteId = useSelector((state: RootState) => state.site.siteInfo.awsPropertyId);

  const handleCheckSIMStatus = async () => {
    setIsCheckingSIMStatus(true);
    const simStatus = await getSiteSubscriptionStatus([parseInt(siteId)]);
    if (simStatus) {
      if (simStatus.data[0].subscription_status === 'active') {
        setHasSIMSubscription(true);
      }
    }
    setIsCheckingSIMStatus(false);
  };

  return (
    <Box>
      <Typography variant="wizardSectionHeader">{t('Sim_Billing')}</Typography>
      <Box sx={{ my: 1 }}>
        <Typography variant="body1">{t('Gateway_SIM_Info_Desc')}</Typography>
      </Box>
      <Box sx={{ mt: 2, mb: 1, display: 'flex', gap: '10px' }}>
        {hasSIMSubscription ? (
          <Button
            variant="outlined"
            sx={{
              color: '#2E7D32'
            }}
          >
            {t('Button_SIM_Activated')}
          </Button>
        ) : (
          <>
            <Link to={`/simbilling/sites/${siteId}/gwdevices`} target="_blank">
              <Button startIcon={<OpenInNewIcon />} variant="outlined" disabled={!macAddressConfirmed}>
                {t('Button_Activate_SIM')}
              </Button>
            </Link>

            <LoadingButton
              variant="outlined"
              disabled={!macAddressConfirmed}
              onClick={handleCheckSIMStatus}
              loading={isCheckingSIMStatus}
            >
              {t('Button_Check_SIM_Status')}
            </LoadingButton>
          </>
        )}
      </Box>

      {!hasSIMSubscription && (
        <Box sx={{ mb: 1 }}>
          <Typography variant="dialogSubTitle" sx={{ color: 'error.main' }}>
            {t('Gateway_No_SIM_Sub')}
          </Typography>
        </Box>
      )}

      <Typography variant="dialogSubTitle">{t('Gateway_SIM_Info_Note_1')} </Typography>
      <Link to="https://www.aiphone.com/wp-content/uploads/2024/08/IXGW-TGW-SIM-Billing-Guide.pdf" target="_blank">
        <Typography variant="dialogSubTitle">{t('Gateway_SIM_Info_Note_2')} </Typography>
      </Link>
    </Box>
  );
};

export default GatewaySIMInfo;

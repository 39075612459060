import React, { useState, useEffect, useRef, useContext } from 'react';
import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import SnackbarAlert from '../../../../shared/components/SnackbarAlert';
import { AuthContext } from '../../../../context/AuthContext';
import { loginUser } from '../../../../shared/api/Aws/authApi';

export default function MFAResendComponent() {
  const TOTAL_TIME = 120;
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  const navigate = useNavigate();
  const [remainingTime, setRemainingTime] = useState(TOTAL_TIME);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const { mfaTypeId, username, password, setSession } = useContext(AuthContext);
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const lastSentTimeStr = localStorage.getItem('lastCodeSentTime');
    if (lastSentTimeStr) {
      const lastSentTime = parseInt(lastSentTimeStr, 10);
      const timeElapsed = Date.now() - lastSentTime;
      const remaining = Math.max(0, TOTAL_TIME * 1000 - timeElapsed);

      if (remaining > 0) {
        setRemainingTime(Math.floor(remaining / 1000));
        setIsButtonDisabled(true);
        startTimer(remaining);
      }
    }

    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, []);

  const startTimer = (duration: number) => {
    setIsButtonDisabled(true);
    let time = Math.floor(duration / 1000);

    if (timerRef.current) {
      clearInterval(timerRef.current);
    }

    timerRef.current = setInterval(() => {
      time -= 1;
      setRemainingTime(time);

      if (time <= 0) {
        if (timerRef.current) {
          clearInterval(timerRef.current);
        }
        setIsButtonDisabled(false);
      }
    }, 1000);
  };

  const handleSendCode = async () => {
    try {
      setIsLoading(true);
      await handleResendCode();
      setIsLoading(false);

      localStorage.setItem('lastCodeSentTime', Date.now().toString());

      setRemainingTime(TOTAL_TIME);
      startTimer(TOTAL_TIME * 1000);
    } catch (error) {
      setErrorMessage(t('MFA_Settings_Error_Message'));
      setIsError(true);
    }
  };

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };

  /**
   * Handles resending MFA code by reinitiating the login process.
   */
  const handleResendCode = async () => {
    try {
      setIsLoading(true);
      // If some data is lost (for example, if the user clears the cache), redirect them to the login page.
      if (!mfaTypeId || !username || !password) {
        navigate('/auth/login');
        return;
      }

      const loginUserPayload = {
        userData: {
          email: username,
          password: password
        }
      };

      await loginUser(loginUserPayload);
    } catch (error: { code?: string }) {
      if (error?.code === 'smsMfaIsRequired' || error?.code === 'emailMfaIsRequired') {
        setSuccessMessage(t('MFA_Settings_Success_Message'));
        setIsSuccess(true);
        setSession(error.session);
        return;
      }
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <SnackbarAlert type="error" time={6000} text={errorMessage} isOpen={isError} onClose={() => setIsError(false)} />
      <SnackbarAlert
        type="success"
        time={6000}
        text={successMessage}
        isOpen={isSuccess}
        onClose={() => setIsSuccess(false)}
      />
      {isButtonDisabled ? (
        <Typography variant="caption">{formatTime(remainingTime)}</Typography>
      ) : (
        <LoadingButton size="small" loading={isLoading} onClick={handleSendCode} disabled={isButtonDisabled}>
          {t('Resend_MFA_Code')}
        </LoadingButton>
      )}
    </>
  );
}

import { FC } from 'react';
import { Box, Divider, Typography } from '@mui/material';

const DialogHeader: FC<{ title: string; description: string }> = ({ title, description }) => {
  return (
    <Box>
      <Typography variant="dialogTitle">{title}</Typography>
      <Typography variant="dialogSubTitle">{description}</Typography>
      <Divider sx={{ my: 2 }} />
    </Box>
  );
};

export default DialogHeader;

import { Box, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { t } from 'i18next';
import { FC } from 'react';
import { AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT } from 'shared/constantAwsApi';

const GatewayModelSelect: FC<{
  gatewayModel: string;
  setGatewayModel: (gatewayModel: string) => void;
  setIsUsingSim: (isUsingSIM: boolean) => void;
}> = ({ gatewayModel, setGatewayModel, setIsUsingSim }) => {
  return (
    <Box sx={{ my: 2 }}>
      <Typography variant="wizardSectionHeader">{t('Gateway_Model_Select_Desc')}</Typography>
      <RadioGroup
        value={gatewayModel}
        onChange={(_, value) => {
          setGatewayModel(value);
          if (value === '1') {
            setIsUsingSim(false);
          }
        }}
      >
        <FormControlLabel value={14} control={<Radio />} label="IXGW-TGW" />
        <Box
          sx={{
            width: '150px',
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <img src={`${AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT}/icons/IXGW-TGW.png`} />
        </Box>
        <FormControlLabel value={1} control={<Radio />} label="IXGW-GW" />
        <Box
          sx={{
            width: '150px',
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <img src={`${AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT}/icons/IXGW-GW.png`} />
        </Box>
      </RadioGroup>
    </Box>
  );
};
export default GatewayModelSelect;

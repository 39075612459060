import CardComponent from './CardComponent';
import { Cached, PhoneIphone } from '@mui/icons-material';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getSite } from 'store/slices/siteSlice';
import CONFIG from 'config';
import { getSerialNumber } from '../Utils/SiteUtil';
import { RootState } from 'store';
import { useUpdateDeviceMutation } from 'services/aiphoneCloud';
import { useSiteContext } from '../SiteContext/SiteContext';
import { useAclAuth } from 'features/SimBilling/Hooks';

/**
 * IXG App Configuration Card Component
 * @returns IXG App Configuration Card Component
 */
const IXGAppConfigurationCard = () => {
  const {
    hasEditPermission,
    isGWRegistered,
    isGatewayUnregistered,
    setErrorMessage,
    setIsLoginDialogOpen,
    setSyncDialogTitle,
    setSyncDialogContent,
    setIsSyncDialogOpen,
    setShowIXGSync
  } = useSiteContext();
  const { t } = useTranslation();
  const { aclToken } = useAclAuth();

  const site = useSelector(getSite);
  const propertyId = site.siteInfo.awsPropertyId;
  const siteId = site?.siteInfo?.publicId;
  const callingUserPublicId = localStorage.getItem('userId') ?? '';
  const { DeviceList: devices } = useSelector((state: RootState) => state.devices);
  const [updateDevice] = useUpdateDeviceMutation();
  const unitList = useSelector((state: RootState) => state.units.UnitList);

  // isFirstSyncToIXGCloud is true when we load the page for the first time
  const [isFirstSyncToIXGCloud, setIsFirstSyncToIXGCloud] = useState(true);
  const gateway = useSelector(
    (state: RootState) => state.devices.DeviceList[site?.siteInfo?.registeredGatewayPublicId]
  );
  const appList = useSelector((state: RootState) => state.apps.AppList);

  const unitsList = Object.values(unitList);
  const unitIds: string[] = [];
  const roomIds: number[] = [];

  if (Array.isArray(unitsList)) {
    for (let i = 0, len = unitsList.length; i < len; i++) {
      if (unitsList[i].appPublicIds !== null) {
        unitIds.push(unitsList[i].publicId);
        roomIds.push(unitsList[i].unitSequentialNumber);
      }
    }
  }

  const handleOpenLoginDialog = () => {
    setIsLoginDialogOpen(true);
  };

  /** Sync with IXG Cloud */
  const syncAcl = useCallback(async () => {
    try {
      // find any devices with device type 18
      const adaptor = Object.values(devices).find((device) => device.basicInfo.deviceType === 18);
      if (adaptor && !isGWRegistered) {
        if (adaptor.gatewaySettings?.gatewaySerialNumber !== null) {
          const serial = getSerialNumber(adaptor.basicInfo.macAddress);
          // update device with serial number
          const updatedDeviceParams = {
            device: {
              publicId: adaptor.publicId,
              basicInfo: {
                serialNumber: serial
              }
            }
          };
          updateDevice(updatedDeviceParams);
        }
      }

      // check if there are any units
      const areThereAnyUnits = Object.values(unitList).length > 0;
      if (!areThereAnyUnits) {
        throw new Error(t('Error_No_Units_Found'));
      }

      const dialogTitle = t('Sync_IXGCloudTitle');
      const dialogContent = t('Sync_IXGCloudContent');
      setSyncDialogTitle(dialogTitle);
      setSyncDialogContent(dialogContent);
      setIsSyncDialogOpen(true);

      if (isFirstSyncToIXGCloud) {
        const sync_result = await fetch(CONFIG.openApiEndpoint + '/syncAcl', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            action: 'upload',
            propertyId: propertyId,
            token: aclToken,
            siteId,
            userId: callingUserPublicId
          })
        });
        if (sync_result.status !== 200) {
          throw new Error('Failed to sync with IXG Cloud');
        }
        setIsFirstSyncToIXGCloud(false);

        const confirm_results = await fetch(CONFIG.openApiEndpoint + '/syncAcl', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            action: 'getIntercomAccessDate',
            propertyId: propertyId,
            token: aclToken,
            clientId: gateway.gatewaySettings?.gatewaySerialNumber,
            userId: callingUserPublicId
          })
        });

        if (confirm_results.status !== 200) {
          const errorMessages: { [key: string]: string } = {
            401: t('Gateway_Error_message.RM-GW-100'),
            500: t('Gateway_Error_message.RM-GW-101')
          };

          throw new Error(errorMessages[confirm_results.status] || t('Gateway_Error_message.RM-GW-199'));
        }

        // wait 1 minute
        await new Promise((resolve) => setTimeout(resolve, 60000));
      }
      const qrResult = await fetch(CONFIG.openApiEndpoint + '/syncAcl', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          action: 'authcode',
          propertyId: propertyId,
          token: aclToken,
          siteId,
          userId: callingUserPublicId,
          unitIds: unitIds,
          roomIds: roomIds
        })
      });

      const message = await qrResult.body
        ?.getReader()
        .read()
        .then(({ value }) => new TextDecoder().decode(value));

      const appUnitIds = [];
      const appIds = [];
      for (const app in appList) {
        appUnitIds.push(appList[app].unitPublicId);
        appIds.push(app);
      }

      const sync_result = await fetch(CONFIG.openApiEndpoint + '/syncAcl', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          action: 'setqrcodes',
          userId: callingUserPublicId,
          unitIds: appUnitIds,
          appIds
        })
      });

      if (message === 'Success' && sync_result.status === 200) {
        // show success message
        setShowIXGSync(true);
      }
      setIsSyncDialogOpen(false);
    } catch (error) {
      setErrorMessage(t('Failed_to_sync_with_IXG_Cloud') + (error as Error).message);
      setIsSyncDialogOpen(false);
    }
  }, [site, devices, unitList, appList, unitIds, roomIds, isFirstSyncToIXGCloud, t]);

  return (
    <CardComponent
      icon={<PhoneIphone />}
      title={t('IXG_App_Configuration')}
      descriptions={
        !aclToken
          ? (t('IXG_Cloud_Login_Descriptions', { returnObjects: true }) as string[])
          : (t('IXG_Cloud_Sync_Descriptions', { returnObjects: true }) as string[])
      }
      buttonText={aclToken ? t('Sync_IXG_Cloud_Button') : t('Login_IXG_Cloud_Button')}
      onClick={aclToken ? syncAcl : handleOpenLoginDialog}
      buttonIcon={<Cached />}
      isButtonEnabled={isGatewayUnregistered || isGWRegistered}
      hasError={!aclToken}
      hasEditPermission={hasEditPermission}
    />
  );
};

export default IXGAppConfigurationCard;

import { Box, Button, Card, CardActions, Typography } from '@mui/material';
import { cloneElement, ReactElement, ReactNode } from 'react';
import { Colors } from '../../../../../../../src/values/common';

interface CardComponentProps {
  icon: ReactNode;
  title: string;
  descriptions: string | string[];
  buttonText: string;
  isButtonEnabled: boolean;
  onClick: () => void;
  buttonIcon: ReactNode;
  hasError?: boolean;
  hasEditPermission: boolean;
}

const CardComponent = ({
  icon,
  title,
  descriptions,
  buttonText,
  isButtonEnabled,
  onClick,
  buttonIcon,
  hasError = false,
  hasEditPermission
}: CardComponentProps) => {
  const iconColor = hasError ? Colors.Orange : Colors.Blue;
  const borderColor = hasError ? Colors.Orange : Colors.Gray;
  const buttonColor = hasError ? Colors.Orange : Colors.Black;

  const descriptionArray = Array.isArray(descriptions) ? descriptions : [descriptions];

  return (
    <Card sx={styles.stationManagerCards(borderColor)}>
      <Box sx={styles.iconColumn}>{cloneElement(icon as ReactElement, { sx: { color: iconColor } })}</Box>
      <Box sx={styles.textColumn}>
        <Typography variant="h6">{title}</Typography>
        {descriptionArray.map((desc, index) => (
          <Typography key={index} variant="caption" sx={styles.description}>
            {desc}
          </Typography>
        ))}
        <CardActions sx={styles.cardActions}>
          <Button
            variant="outlined"
            onClick={onClick}
            disabled={!isButtonEnabled || !hasEditPermission}
            startIcon={buttonIcon}
            sx={{ color: buttonColor, borderColor: buttonColor }}
          >
            {buttonText}
          </Button>
        </CardActions>
      </Box>
    </Card>
  );
};

/** @type {import('@mui/material'.SxProps)} */
const styles = {
  stationManagerCards: (color: string) => ({
    width: 290,
    height: 160,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    border: `1px solid ${color}`,
    backgroundColor: '#fff',
    boxShadow: `0px 0px 0px 1px #E0E0E0`,
    padding: '10px'
  }),
  cardActions: {
    justifyContent: 'flex-start',
    marginTop: 'auto',
    paddingLeft: '0'
  },
  description: {
    marginBottom: '4px',
    lineHeight: '1.3'
  },
  iconColumn: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginRight: '10px'
  },
  textColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
    height: '100%'
  }
};

export default CardComponent;

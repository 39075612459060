import { Box, Button, Dialog, DialogContent, DialogTitle, IconButton, Theme, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { t } from 'i18next';
import { gwCommand } from 'shared/rmGateway/gwCommand';
import { fetchGatewayCommand } from 'shared/rmGateway/gwCommandProcessor';
import { getGWErrorCode } from 'shared/rmGateway/gwErrorHandler';
import { Status } from 'shared/utils/Status';
import { firmwareUrl } from '../Utils/SiteUtil';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { getSite } from 'store/slices/siteSlice';
import { useHandleGatewayCommandMutation, useUpdateDeviceMutation } from 'services/aiphoneCloud';
import { useSiteContext } from '../SiteContext/SiteContext';

const GWFirmwareDialog = () => {
  const {
    rows,
    setRows,
    setGwOnlineStatus,
    isGWFirmwareDialogOpen,
    setGWFirmwareDialogOpen,
    setSyncStatus,
    latestFirmwareList,
    setErrorMessage,
    setSuccessMessage,
    setShowAlert,
    setSyncDialogContent,
    setSyncDialogTitle,
    setIsSyncDialogOpen
  } = useSiteContext();

  const site = useSelector(getSite);
  const gateway = useSelector(
    (state: RootState) => state.devices.DeviceList[site?.siteInfo?.registeredGatewayPublicId]
  );
  const [handleGatewayCommand] = useHandleGatewayCommandMutation();
  const [updateDevice] = useUpdateDeviceMutation();
  const isGatewayFirstSync = gateway?.lastSyncedOn === null;

  /** Handle the update gateway firmware button */
  const handleUpdateGatewayFirmwareButton = async () => {
    setGWFirmwareDialogOpen(false);
    // set the gateway's row as busy
    const dialogTitle = t('Gateway_GatewayFirmwareUpdate_Title');
    const dialogContent = t('Gateway_GatewayFirmwareUpdate_Content');
    setSyncDialogTitle(dialogTitle);
    setSyncDialogContent(dialogContent);
    setIsSyncDialogOpen(true);
    setGwOnlineStatus(Status.Busy);
    setSyncStatus(Status.Waiting);

    const deviceModel = 'IXGW-GW';
    const selectedDevice = gateway;

    const latestFirmwareName = latestFirmwareList[deviceModel].enhanced.name;
    const deviceFirmwareUrl = firmwareUrl + latestFirmwareList[deviceModel].enhanced.name;
    const downloadResponse = await fetch(deviceFirmwareUrl);
    const isDeviceFirstSync = selectedDevice.lastSyncedOn === null;
    const systemId = site?.siteInfo?.systemId;
    const systemPassword = site?.siteInfo?.systemPassword;

    const url = await downloadResponse.text();
    const gatewayInfo = {
      awsPropertyId: site?.siteInfo?.awsPropertyId,
      gwMacAddress: gateway?.basicInfo?.macAddress,
      gwId: systemId ? systemId : isGatewayFirstSync ? 'admin' : gateway?.basicInfo.adminId,
      gwPassword: systemPassword ? systemPassword : isGatewayFirstSync ? 'admin' : gateway?.basicInfo.adminPass,
      gwIpAddress: gateway?.networkSettings?.ipV4Address || ''
    };
    const deviceInfo = {
      deviceIpAddress: selectedDevice?.networkSettings?.ipV4Address,
      deviceMacAddress: selectedDevice?.basicInfo?.macAddress,
      deviceType: selectedDevice?.basicInfo.deviceType,
      deviceId: systemId ? systemId : isDeviceFirstSync ? 'admin' : selectedDevice?.basicInfo.adminId,
      devicePassword: systemPassword
        ? systemPassword
        : isDeviceFirstSync
        ? 'admin'
        : selectedDevice?.basicInfo.adminPass,
      deviceFirmwareFileName: latestFirmwareName,
      deviceFirmwareLink: url
    };

    try {
      const ioTPayload = fetchGatewayCommand('sendCommand', gwCommand.FIRMWARE_UPDATE, gatewayInfo, deviceInfo, null);
      // set status to Updating
      setGwOnlineStatus(Status.Updating);
      await handleGatewayCommand(ioTPayload).unwrap();

      // wait 5 minutes
      // TODO: improve this to check the status of the firmware update, especially if there is an early failure response
      await new Promise((resolve) => setTimeout(resolve, 300000));

      const fetchPayload = fetchGatewayCommand('fetchResult', gwCommand.FIRMWARE_UPDATE, gatewayInfo, deviceInfo, null);
      let fetchResponse = await handleGatewayCommand(fetchPayload).unwrap();

      if (fetchResponse?.statusCode.includes('206')) {
        fetchResponse = await handleGatewayCommand(fetchPayload).unwrap();
      }
      // Check the payload for the ip address to see if firmware update was successful
      const statusCode = fetchResponse?.payload[0].statusCode.slice(0, 3);
      if (statusCode === '200') {
        setShowAlert(true);
        setSuccessMessage(t('Success'));
        // update the device firmware version in redux and the database
        const fwVersion = Number(latestFirmwareList[deviceModel].enhanced.version / 100).toFixed(2);
        const updateDevicePayload = {
          ...selectedDevice,
          basicInfo: {
            ...selectedDevice.basicInfo,
            firmwareVersion: fwVersion
          }
        };
        await updateDevice(updateDevicePayload);
        setRows(rows);
      } else {
        setRows(rows);
        setErrorMessage(t(getGWErrorCode({ message: 'Firmware_Update_Error' })));
      }
    } catch (error) {
      setErrorMessage(t(getGWErrorCode({ message: 'Firmware_Update_Error' })));
    }
    setIsSyncDialogOpen(false);
    setGwOnlineStatus(Status.Online);
  };

  if ('IXGW-GW' in latestFirmwareList) {
    // check if gateway is in the latest firmware list
    return (
      <Dialog open={isGWFirmwareDialogOpen} onClose={() => setGWFirmwareDialogOpen(false)} maxWidth="md" fullWidth>
        <DialogTitle>
          {t('Gateway_GatewayFirmwareUpdate_Title')}
          <IconButton aria-label="close" onClick={() => setGWFirmwareDialogOpen(false)} sx={styles.buttonContainer2}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContent>
            {t('Latest_version_of_firmware')}
            <Typography component="span" style={{ whiteSpace: 'pre' }}>
              {' '}
            </Typography>
            <b>{latestFirmwareList['IXGW-GW'].enhanced.name.replace('.bin', '')}</b> (version{' '}
            {latestFirmwareList['IXGW-GW'].enhanced.version / 100})
            <Typography component="span" style={{ whiteSpace: 'pre' }}>
              {' '}
            </Typography>
            {t('Is_available_for_Gateway')}
            <Typography>{t('Would_you_like_to_proceed_with_updating')}</Typography>
          </DialogContent>
        </DialogContent>
        <Box sx={styles.dialogBox1}>
          <Button onClick={handleUpdateGatewayFirmwareButton} color="primary" variant="outlined">
            {t('Confirm_Update')}
          </Button>
        </Box>
      </Dialog>
    );
  }
};

/** @type {import('@mui/material'.SxProps)} */
const styles = {
  dialogBox1: {
    padding: '15px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'right'
  },
  buttonContainer2: {
    position: 'absolute',
    right: 8,
    top: 8,
    color: (theme: Theme) => theme.palette.grey[500]
  }
};

export default GWFirmwareDialog;

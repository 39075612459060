import { Container } from '@mui/material';
import Site from './Site/Site';
import { SiteProvider } from './Site/SiteContext/SiteContext';

const SiteInfo = () => {
  return (
    <Container maxWidth="xl">
      {
        <SiteProvider>
          <Site />
        </SiteProvider>
      }
    </Container>
  );
};

export default SiteInfo;

import { Box, Button } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { RootState } from 'store';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { TabContext } from '@mui/lab';
import DeviceTabs from '../../shared/components/DeviceTabs';
import DeviceTabPanels from '../../shared/components/DeviceTabPanels';
import { getDeviceTypeModelMapping } from 'store/slices/devicesSlice';
import AddDeviceDialog from 'features/RemoteManagement/Components/AddDeviceDialog';
import { useGetDeviceListWithSitePublicIdQuery } from 'services/aiphoneCloud';

// This component is used to render the existing devices tabs and their content
const ExistingDevices = () => {
  const [currentTabIndex, setCurrentTabIndex] = useState<string | number>('MasterStation');
  const [isAddDevicesWizardOpen, setIsAddDevicesWizardOpen] = useState(false);
  const [shouldFetchDevices, setShouldFetchDevices] = useState(false);
  const deviceListByType = useSelector((state: RootState) => state.devices.DeviceListByType);
  const sitePublicId = useParams().id ?? '';
  const allDevices = useSelector((state: RootState) => state.devices.DeviceList);
  const { refetch } = useGetDeviceListWithSitePublicIdQuery({
    qty: '-1',
    page: 0,
    sitePublicId: sitePublicId
  });

  useEffect(() => {
    if (shouldFetchDevices) {
      refetch();
    }
  }, [shouldFetchDevices, refetch]);

  // This filters out the duplicate devices based on the same MAC Address
  const uniqueDevicesByType = useMemo(() => {
    const uniqueDevices = new Set();
    const sortedDevices: { [key: string]: string[] } = {};

    // Iterate allDevices
    for (const devicePublicId in allDevices) {
      const device = allDevices[devicePublicId];
      const deviceType = device.basicInfo.deviceType;
      const deviceModel = device.basicInfo.deviceModel;

      // Check if device is unique
      if (!uniqueDevices.has(device.basicInfo.macAddress)) {
        uniqueDevices.add(device.basicInfo.macAddress);

        const deviceModelTypeName = getDeviceTypeModelMapping(deviceType, deviceModel);
        if (deviceModelTypeName) {
          if (!sortedDevices[deviceModelTypeName]) {
            sortedDevices[deviceModelTypeName] = [];
          }
          sortedDevices[deviceModelTypeName].push(devicePublicId);
        }
      }
    }

    return sortedDevices;
  }, [allDevices]);

  // Set the current tab index to the first tab that has devices
  useEffect(() => {
    setCurrentTabIndex(
      Object.keys(deviceListByType).find((deviceType) => deviceListByType[deviceType]?.length > 0) || 'MasterStation'
    );
  }, [deviceListByType]);

  return (
    <>
      <Box sx={styles.buttonContainer}>
        <Button variant="contained" onClick={() => setIsAddDevicesWizardOpen(true)}>
          Add Devices
        </Button>
      </Box>
      <TabContext value={currentTabIndex}>
        <DeviceTabs deviceListByType={uniqueDevicesByType} setCurrentTabIndex={setCurrentTabIndex} />
        <DeviceTabPanels deviceListByType={deviceListByType} />
      </TabContext>
      <AddDeviceDialog
        isOpen={isAddDevicesWizardOpen}
        setIsOpen={setIsAddDevicesWizardOpen}
        setShouldFetchDevices={setShouldFetchDevices}
      />
    </>
  );
};

const styles = {
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginBottom: 2
  }
};

export default ExistingDevices;

import { Box, CircularProgress, Dialog, DialogTitle, Typography } from '@mui/material';
import { useSiteContext } from '../SiteContext/SiteContext';

/**
 * interface for SyncDialogProps to SyncDialog
 * @interface SyncDialogProps
 * @property {() => void} handleClose - function to close the dialog
 */
export interface SyncDialogProps {
  handleClose: () => void;
}

/**
 * SyncDialog component
 * @param {SyncDialogProps} props - props object
 * @returns {JSX.Element}
 */
const SyncDialog = ({ handleClose }: SyncDialogProps) => {
  const { gwStatusMessage, isSyncDialogOpen, syncDialogTitle, syncDialogContent } = useSiteContext();

  return (
    <Dialog open={isSyncDialogOpen} onClose={handleClose} style={styles.syncModal} fullWidth maxWidth="md">
      <DialogTitle style={{ display: 'flex', justifyContent: 'center' }}>{syncDialogTitle}</DialogTitle>
      <Box sx={styles.syncModal}>
        {gwStatusMessage ? (
          <Typography variant="caption" textAlign="center">
            {gwStatusMessage}
          </Typography>
        ) : (
          <CircularProgress sx={styles.syncModal} size="40px" color="info" />
        )}
      </Box>
      <Box sx={{ padding: '20px' }}>
        <Typography variant="caption" textAlign="center">
          {syncDialogContent}
        </Typography>
      </Box>
    </Dialog>
  );
};

/** @type {import('@mui/material'.SxProps)} */
const styles = {
  syncModal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    AlignHorizontalCenter: 'center',
    margin: '10px'
  }
};

export default SyncDialog;

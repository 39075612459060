/**
 * Common regular expressions
 */

// NANP phone number regex
// North American Numbering Plan (NANP) phone number regex
// https://en.wikipedia.org/wiki/North_American_Numbering_Plan
// 10-23-24: Just edited so it only permits +1 as the country code.
/**
 * @constant NANP_PHONE_REGEX
 * @description Regular expression to validate North American Numbering Plan (NANP) phone number.
 * The [NANP](https://en.wikipedia.org/wiki/North_American_Numbering_Plan) is a telephone numbering plan that encompasses 25 distinct regions in twenty countries primarily in North America, including the Caribbean and the U.S. territories.
 * This iteration only permits `+1` as the country code, as per our standard.
 */
export const NANP_PHONE_REGEX = /^\+1 \([2-9]\d{2}\) [2-9]\d{2}-\d{4}$/;

/**
 * @constant EMAIL_REGEX
 * @description Regular expression to validate email address
 */
export const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

/**
 * @constant VALID_STATION_NAME_REGEX
 * @description Regular expression to validate URL
 */
export const VALID_STATION_NAME_REGEX = /^[A-Za-z0-9+\-_?./()%$@! ]{1,24}$/;

/**
 * @constant VALID_MAC_ADDRESS_REGEX
 * @description Regular expression to validate MAC address
 */
export const VALID_MAC_ADDRESS_REGEX = /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/;
